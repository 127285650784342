<style>
  .dropdown-menu {
    /* max-height: none !important; */
    overflow-y: auto;
  }
  .filter-modal-position {
    margin-top: 200px;
  }
  .profile-dropdown {
    max-height: 90vh;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
</style>
<nav
  class="navbar navbar-expand-lg navbar-light sticky-navbar"
  [ngClass]="[
    isAuthUser ? 'logedin white-bg ' : 'navbar-gradient',
    status && hideCreateIncidentBtn ? 'expanded-nav' : 'custom-nav',
    changeHeaderGradient && !isAuthUser ? 'navbar-gradient-aboutus-page' : '',
    isAuthUser && !hideCreateIncidentBtn ? 'px-5' : ''
  ]"
>
  <!-- add "logedin" class while user is login-->
  <div class="container-fluid px-0">
    <a
      class="navbar-brand logo logo-sm"
      [ngClass]="
        isAuthUser && !hideCreateIncidentBtn ? 'logo-width-for-new-header' : ''
      "
    >
      <img
        [ngClass]="!hideCreateIncidentBtn ? 'ml-3' : ''"
        *ngIf="isAuthUser; else loggedOut"
        width="152"
        height="41"
        src="assets/images/logofooter_new.png"
        alt="no-img"
        [routerLink]="isAuthUser ? 'feed' : 'home'"
        (click)="reloadComponent()"
        style="cursor: pointer"
      />
      <ng-template #loggedOut>
        <img
          width="152"
          height="41"
          src="assets/images/logonew_new.png"
          alt="no-img"
        />
      </ng-template>

      <div
        *ngIf="
          router.url.split('?')[0] !== '/cheo-incident' &&
          router.url.split('?')[0] !== '/mysubmissions' &&
          router.url.split('?')[0] !== '/myinvestigations' &&
          router.url.split('?')[0] !== '/myassignments' &&
          router.url.split('?')[0] !== '/cheoincidentreview' &&
          router.url.split('?')[0] !== '/edit-cheo-incident'
        "
        [ngClass]="!isAuthUser ? 'ml-3' : ''"
        class="btn-group mr-2"
        dropdown
        style="cursor: pointer"
        dropdownToggle
      >
        <img
          *ngIf="translate.currentLang === 'en'"
          style="height: 18px; width: 18px; margin-top: 10px"
          src="assets/images/united-kingdom.png"
          alt="no-img"
        />
        <img
          *ngIf="translate.currentLang === 'de'"
          style="height: 18px; width: 18px; margin-top: 10px"
          src="assets/images/germany.png"
          alt="no-img"
        />
        <img
          *ngIf="translate.currentLang === 'fr'"
          style="height: 18px; width: 18px; margin-top: 10px"
          src="assets/images/france-flag.png"
          alt="no-img"
        />
        <div
          id="button-split"
          type="button"
          class="dropdown-toggle dropdown-toggle-split"
          aria-controls="dropdown-split"
        ></div>
        <ul
          id="dropdown-split"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="button-split"
        >
          <div *ngFor="let lang of translate.getLangs()">
            <li
              (click)="onLanguageSelection(lang)"
              style="cursor: pointer"
              role="menuitem"
            >
              <div class="d-flex flex-row mx-2">
                <img
                  *ngIf="lang === 'de' && translate.currentLang !== lang"
                  style="height: 18px; width: 18px; margin-top: 8px"
                  src="assets/images/germany.png"
                  alt="no-img"
                />
                <img
                  *ngIf="lang === 'en' && translate.currentLang !== lang"
                  style="height: 18px; width: 18px; margin-top: 8px"
                  src="assets/images/united-kingdom.png"
                  alt="no-img"
                />
                <img
                  *ngIf="lang === 'fr' && translate.currentLang !== lang"
                  style="height: 18px; width: 18px; margin-top: 8px"
                  src="assets/images/france-flag.png"
                  alt="no-img"
                />
                <a class="ml-2" *ngIf="translate.currentLang !== lang">{{
                  lang === 'en'
                    ? 'English'
                    : lang === 'de'
                    ? 'Deutsch'
                    : 'French'
                }}</a>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </a>
    <!-- Please uncoomment below code for before login  -->
    <!-- Beforelogin nav -->

    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      *ngIf="!isAuthUser"
      style="background: white"
      (click)="closeNavbar()"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- <select #langSelect [(ngModel)]="selected" (ngModelChange)="onChange()">
      <option
        *ngFor="let lang of translate.getLangs()"
        [value]="lang"
        [selected]="lang === translate.currentLang"
      >
        {{ lang }}
      </option>
    </select> -->

    <div
      class="collapse navbar-collapse webnav collapse-bg"
      id="navbarSupportedContent"
      *ngIf="!isAuthUser"
    >
      <ul class="navbar-nav ml-auto">
        <li [ngClass]="{ active: router.url.includes(URLConstants.HOMEPAGE) }">
          <a
            [routerLink]="[URLConstants.HOMEPAGE]"
            class="about-banner-content-font"
            (click)="closeNavbar()"
            >{{ 'Home' | translate }}</a
          >
        </li>
        <li [ngClass]="{ active: router.url.includes(URLConstants.BLOG) }">
          <a
            [routerLink]="[URLConstants.BLOG]"
            class="about-banner-content-font"
            (click)="closeNavbar()"
            >{{ 'Podcasts' | translate }}</a
          >
        </li>
        <li [ngClass]="{ active: router.url.includes(URLConstants.CONTACT) }">
          <a
            [routerLink]="[URLConstants.CONTACT]"
            class="about-banner-content-font"
            (click)="closeNavbar()"
            >{{ 'Contact Us' | translate }}</a
          >
        </li>
      </ul>
      <button
        type="button"
        class="btn btn-primary mt-2 mt-md-0"
        id="signIn"
        #signIn
        (click)="openModal(template); closeNavbar()"
      >
        {{ 'Sign In' | translate }}
      </button>
    </div>
    <!-- Beforelogin nav End-->
    <!-- After login  Start-->

    <div
      class="webnav after-login-menu d-flex flex-row justify-content-between"
      *ngIf="isAuthUser"
    >
      <span
        *ngIf="router.url.split('?')[0] === '/feed'"
        (click)="clickEvent()"
        [ngClass]="status ? 'open' : 'close'"
        class="ic-icon icon-search search-open d-lg-none text-black"
      ></span>

      <div *ngIf="!hideCreateIncidentBtn" class="w-25"></div>
      <div
        *ngIf="router.url.split('?')[0] !== '/feed'"
        class="search form-inline my-2 my-lg-0"
      ></div>
      <form
        *ngIf="router.url.split('?')[0] === '/feed'"
        [formGroup]="searchForm"
        class="search form-inline input-icon my-2 my-lg-0"
      >
        <label class="sr-only" for="search">Email</label>
        <div
          [ngClass]="
            hideCreateIncidentBtn && status
              ? 'mobile-search-container'
              : 'search-container'
          "
        >
          <span class="icon-search ml-3 mr-1"></span>
          <!-- <input class="form-control mr-lg-2" #mysearch id="search" type="search" placeholder="Search"
                    aria-label="Search" autocomplete="on"> -->
          <!-- <ng-select class="text-input" style="margin: 10px;width: 600px;" [items]="internalAssigneList|async"
                    [clearable]="true" bindLabel="name" bindValue="_id" name="searchText" required [searchable]='true'
                    autocomplete="search" placeholder="Search Text ...." formControlName="searchText"
                    [typeaheadMinLength]="0" [notFoundText]="notFoundText1" [typeahead]="internalAssigneDataSource"
                    (focus)="focus$.next($event.target.value)">
                </ng-select> -->

          <!-- <ng-select *ngIf="!isSearch" class="text-input" placeholder="Search..." [clearable]='true'
                    [searchable]='true' [dropdownPosition]="'auto'" [ngModelOptions]="{standalone: true}"
                    (focus)="onFocus()" [(ngModel)]="searchText" name="searchText" [items]="searchList" bindLabel="name"
                    [bindValue]="" (keydown.enter)="universalSearch($event,'enter')"
                    (keyup)="keyUpsearch($event,'enter')" #mysearch (clear)="clearSearch()">
                </ng-select> -->
          <ng-select
            *ngIf="industry === 'aviation'"
            [ngClass]="
              status && hideCreateIncidentBtn
                ? 'mobile-search'
                : 'custom-search'
            "
            class="search-custom-slectmenu"
            placeholder="{{ 'Search with airport/aircraft etc' | translate }}"
            [clearable]="true"
            [searchable]="true"
            [dropdownPosition]="'auto'"
            [ngModelOptions]="{ standalone: true }"
            [(ngModel)]="searchText"
            name="searchText"
            [items]="searchItems | async"
            [typeahead]="typeAheadSourceSearch"
            bindLabel="name"
            [groupBy]="'type'"
            bindValue=""
            (keydown.enter)="universalSearch($event, 'enter')"
            (ngModelChange)="searchText = $event"
            (ngModelChange)="universalSearch($event, 'enter')"
            #mysearch
            (clear)="clearSearch()"
            [notFoundText]="noResult"
          >
          </ng-select>

          <ng-select
            *ngIf="industry === 'healthcare'"
            [ngClass]="
              status && hideCreateIncidentBtn
                ? 'mobile-search'
                : 'custom-search'
            "
            class="search-custom-slectmenu"
            placeholder="{{ 'Search' | translate }}"
            [clearable]="true"
            [searchable]="true"
            [dropdownPosition]="'auto'"
            [ngModelOptions]="{ standalone: true }"
            (focus)="onFocus()"
            [(ngModel)]="searchText"
            name="searchText"
            [items]="searchItems"
            bindLabel="name"
            bindValue=""
            (keydown.enter)="universalSearch($event, 'enter')"
            (ngModelChange)="searchText = $event"
            (ngModelChange)="universalSearch($event, 'enter')"
            #mysearch
            (clear)="clearSearch()"
            [notFoundText]="noResult"
          >
          </ng-select>
          <span
            *ngIf="!hideCreateIncidentBtn"
            tooltip="Filters"
            (click)="openModal17(filter)"
            class="icon-equalizer text-black ml-2 mr-3"
            style="font-size: 18px; cursor: pointer"
          ></span>
        </div>
        <!-- <div class="d-xs-none" *ngIf="!hideFilterButton">
          <button
            class="btn-filter ml-2 mr-4"
            #filterButton
            id="filterButton"
            (click)="openModal17(filter)"
            tooltip="{{ 'Filters' | translate }}"
          >
            <span
              class="icon-equalizer text-black"
              style="font-size: 18px"
            ></span>
          </button>
        </div> -->

        <!-- <div *ngIf="!hideCreateIncidentBtn">
          <button
            class="btn btn-primary w-100 m-4"
            [routerLink]="
              userData.industry === 'healthcare'
                ? [URLConstants.CREATEINCIDENT]
                : [URLConstants.CREATEAVIATIONINCIDENT]
            "
          >
            {{ 'Contribute' | translate }}
          </button>
        </div> -->
      </form>
      <div *ngIf="!hideCreateIncidentBtn" class="w-25"></div>
      <ul class="navbar-nav">
        <li (click)="openModal17(filter)">
          <span class="icon-equalizer mt-1 d-block d-lg-none text-black"></span>
        </li>
        <li
          [ngClass]="{ activeLoggedin: router.url.includes(URLConstants.FEED) }"
        >
          <a *ngIf="backToTopButton">
            <span
              style="cursor: pointer"
              (click)="scrollBackToTop()"
              class="icon-home mt-1 d-block"
            ></span>
          </a>
          <a *ngIf="!backToTopButton" [routerLink]="[URLConstants.FEED]">
            <span class="icon-home mt-1 d-block"></span>
          </a>
        </li>
        <li>
          <a class="notifiaction" [routerLink]="[URLConstants.NOTIFICATION]">
            <span class="icon-notifiaction mt-1 d-block"></span>
            <span class="notification-count">{{
              userData.notificationCount ? userData.notificationCount : '0'
            }}</span>
          </a>
        </li>

        <li class="nav-item dropdown">
          <a
            class="dropdown-toggle logged-in-nav-text"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <picture class="dropdown-profile">
              <img
                width="32"
                height="32"
                [src]="userData.photo ? userData.photo : defaultImage"
                alt="no img"
                (error)="img.src = defaultImage"
                #img
              />
            </picture>
            <span class="ml-2">{{
              userData.fakeUsername ? userData.fakeUsername : 'loading...'
            }}</span>
          </a>
          <div
            class="dropdown-menu profile-dropdown"
            aria-labelledby="navbarDropdown"
          >
            <!-- <button class="btn btn-secondary w-100" [routerLink]="[URLConstants.CREATEINCIDENT]">Create an
                            Incident</button> -->
            <ul class="profile-list list-unstyled mt-2">
              <li>
                <a
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.MYPROFILE]"
                >
                  <span class="icon-my-profile"></span>
                  {{ 'My Profile' | translate }}
                </a>
              </li>
              <li *ngIf="logedUserData?.institution !== 'Cheo'">
                <a
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.EDITPROFILE]"
                  ><span class="icon-edit-profile"></span
                  >{{ 'Edit Profile' | translate }}</a
                >
              </li>
              <li *ngIf="logedUserData?.institution === 'Cheo'">
                <a
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.EDITPROFILEINSTITUTION]"
                  ><span class="icon-edit-profile"></span
                  >{{ 'Edit Profile' | translate }}</a
                >
              </li>
              <li
                *ngIf="
                  logedUserData?.institution !== 'Cheo' &&
                  logedUserData?.institution !== 'Bruyere' &&
                  logedUserData?.institution !== 'Perley'
                "
              >
                <a
                  (click)="onTabLinkClick('Incidents')"
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.MYCONTRIBUTION]"
                  ><span class="icon-contribution"></span
                  >{{ 'My Contributions' | translate }}</a
                >
              </li>
              <li
                *ngIf="
                  (logedUserData?.institution === 'Cheo' ||
                    logedUserData?.institution === 'Bruyere' ||
                    logedUserData?.institution === 'Perley') &&
                  (logedUserData?.permission.includes('Investigator') ||
                    logedUserData?.permission.includes('Submitter'))
                "
              >
                <a
                  (click)="onTabLinkClick('inreview')"
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.MYSUBMISSIONS]"
                  ><span class="icon-contribution"></span>
                  {{ 'My Submissions' | translate }}</a
                >
              </li>
              <li
                *ngIf="
                  (logedUserData?.institution === 'Cheo' ||
                    logedUserData?.institution === 'Bruyere' ||
                    logedUserData?.institution === 'Perley') &&
                  logedUserData?.permission.includes('Investigator')
                "
              >
                <a
                  (click)="onTabLinkClick('new')"
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.MYINVESTIGATIONS]"
                  ><span class="icon-contribution"></span>
                  {{ 'My Investigations' | translate }}</a
                >
              </li>
              <li
                *ngIf="
                  logedUserData?.institution === 'Cheo' ||
                  logedUserData?.institution === 'Bruyere' ||
                  logedUserData?.institution === 'Perley'
                "
              >
                <a
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.MYASSIGNMENTS]"
                  ><span class="icon-contribution"></span>
                  {{ 'Action Items' | translate }}</a
                >
              </li>
              <li>
                <a
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.FAVOURITE]"
                  ><span class="icon-heart"></span
                  >{{ 'Favorites' | translate }}</a
                >
              </li>
              <li *ngIf="hideCreateIncidentBtn">
                <a class="logged-in-nav-text" [routerLink]="['/news']"
                  ><span class="icon-globale"></span>News</a
                >
              </li>
              <li *ngIf="hideCreateIncidentBtn">
                <a class="logged-in-nav-text" [routerLink]="['/essentialtools']"
                  ><span class="icon-hear2"></span>Essential Tools</a
                >
              </li>
              <li (click)="openModal(contactus)">
                <a style="cursor: pointer" class="logged-in-nav-text"
                  ><span class="icon-phone1"></span
                  >{{ 'Contact Us' | translate }}</a
                >
              </li>
              <li>
                <a
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.TERMSANDCONDITION]"
                  ><span class="icon-Term-and-condition"></span
                  >{{
                    'Terms &
                  Conditions' | translate
                  }}</a
                >
              </li>
              <li>
                <a
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.PRIVACYPOLICY]"
                  ><span class="icon-privacy-policy"></span
                  >{{ 'Privacy Policy' | translate }}</a
                >
              </li>
              <li>
                <a
                  class="logged-in-nav-text"
                  [routerLink]="[URLConstants.SETTINGS]"
                  ><span class="icon-settings"></span
                  >{{ 'Settings' | translate }}</a
                >
              </li>
              <li>
                <a class="logged-in-nav-text" [routerLink]="[URLConstants.FAQS]"
                  ><span class="icon-heart"></span>{{ 'FAQs' | translate }}</a
                >
              </li>
              <li>
                <a
                  class="logged-in-nav-text"
                  (click)="logout()"
                  href="javascript:;"
                  ><span class="icon-logout"></span
                  >{{ 'Logout' | translate }}</a
                >
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
    <!-- After login  End-->
  </div>
</nav>
<!-- Main Navigation -->
<div class="custom-model">
  <ng-template #template>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide(); isSignInsubmitted = false"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="common-form">
        <h2 class="text-center form-title">{{ 'Sign In' | translate }}</h2>
        <form [formGroup]="signInForm" (ngSubmit)="signInSubmit()">
          <div class="form-group input-icon">
            <label class="sr-only" for="emailOrLoginId">{{
              'Email' | translate
            }}</label>
            <span class="ic-icon icon-email"></span>
            <input
              class="form-control"
              placeholder="{{ 'Email/Login ID' | translate }}"
              type="email"
              id="email"
              formControlName="emailOrLoginId"
            />
            <span
              class="error-msg"
              *ngIf="
                (isSignInsubmitted ||
                  signInForm.controls.emailOrLoginId.touched) &&
                signInForm.controls.emailOrLoginId.invalid
              "
            >
              {{
                showErrorService.showError(
                  'emailOrLoginId',
                  signInForm.controls.emailOrLoginId.errors
                )
              }}
            </span>
          </div>
          <div class="form-group input-icon">
            <label class="sr-only" for="password">{{
              'Password' | translate
            }}</label>
            <span class="ic-icon icon-password"></span>
            <input
              type="{{ passstatus ? 'text' : 'password' }}"
              class="form-control"
              placeholder="{{ 'Password' | translate }}"
              type="password"
              id="password"
              formControlName="password"
            />
            <span
              (click)="passwordEvent()"
              [ngClass]="passstatus ? 'icon-passshow' : 'icon-passhide'"
              class="pass-icon"
            ></span>
            <span
              class="error-msg"
              *ngIf="
                (isSignInsubmitted || signInForm.controls.password.touched) &&
                signInForm.controls.password.invalid
              "
            >
              {{
                showErrorService.showError(
                  'password',
                  signInForm.controls.password.errors
                )
              }}
            </span>
          </div>
          <div class="forgot-password-block text-center my-3 my-md-4">
            <a
              class="forgotpass"
              href="javascript:void(0)"
              (click)="openModal3(forgotpass); modalRef.hide()"
              >{{ 'Forgot Password?' | translate }}</a
            >
          </div>
          <div class="text-center my-2">
            <re-captcha
              siteKey="6Lc0G_cfAAAAANEXWwWETh4F7N-bLDXMTK1AnvfJ"
              formControlName="captcha"
              name="captcha"
              required
              class="mx-auto"
              style="display: inline-block"
            ></re-captcha>
          </div>
          <div class="btn-box text-center">
            <!-- <button type="submit" class="large-btn btn-primary">
              {{ 'Submit' | translate }}
            </button> -->

            <button
              type="submit"
              class="large-btn btn-primary"
              [disabled]="isSubmitting"
            >
              <span
                *ngIf="isSubmitting"
                class="spinner-border spinner-border-sm mr-2"
              ></span>
              {{
                isSubmitting
                  ? ('Submitting' | translate)
                  : ('Submit' | translate)
              }}
            </button>
          </div>
          <p class="text-danger text-center mt-3">{{ attemptsLeft }}</p>
          <div class="signup-block text-center mt-3 mt-md-4">
            <p>
              {{ 'Don’t have an account?' | translate
              }}<a
                href="javascript:void(0)"
                class="forgotpass pl-1"
                (click)="
                  showRedirectModal
                    ? openModal8(template8)
                    : openModal2(template2);
                  modalRef.hide()
                "
                >{{ 'Sign Up' | translate }}</a
              >
            </p>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
<!--contact us modal-->
<div class="custom-model">
  <ng-template #contactus>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef.hide(); contactSubmitted = false"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="common-form">
        <h2 class="text-center form-title">{{ 'Contact Us' | translate }}</h2>
        <form [formGroup]="contactForm" (ngSubmit)="submitReachForm()">
          <div class="form-group">
            <label class="sr-only" for="name">{{ 'Name' | translate }}</label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="{{ 'Name' | translate }}"
              formControlName="name"
            />
            <span
              class="error-msg"
              *ngIf="(contactSubmitted || cf.name.touched) && cf.name.invalid"
            >
              {{ showErrorService.showError('name', cf.name.errors) }}
            </span>

            <div class="form-group mt-3">
              <label class="sr-only" for="email">{{
                'Email' | translate
              }}</label>
              <input
                type="text"
                class="form-control"
                placeholder="{{ 'Email' | translate }}"
                type="Email"
                id="email"
                formControlName="emailId"
              />
              <span
                class="error-msg"
                *ngIf="
                  (contactSubmitted || cf.emailId.touched) && cf.emailId.invalid
                "
              >
                {{ showErrorService.showError('email', cf.emailId.errors) }}
              </span>
            </div>
          </div>

          <div class="form-group">
            <label class="sr-only" for="Message">Example textarea</label>
            <textarea
              class="form-control"
              id="Message"
              name="massage"
              placeholder="{{ 'Message' | translate }}"
              rows="5"
              formControlName="message"
            ></textarea>
            <span
              class="error-msg"
              *ngIf="
                (contactSubmitted || cf.message.touched) && cf.message.invalid
              "
            >
              {{ showErrorService.showError('message', cf.message.errors) }}
            </span>
          </div>
          <div class="btn-box text-center mt-4 mt-md-5">
            <button type="submit" class="large-btn btn-primary">
              {{ 'Submit' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
<!--contact us modal end-->

<!-- Login Popup End -->
<div class="custom-model">
  <ng-template #template2>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="
          modalRef2.hide(); isRegsubmitted = false; isSignInsubmitted = false
        "
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="common-form">
        <h2 class="text-center form-title">Sign Up</h2>
        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
          <div class="form-group input-icon">
            <label class="sr-only" for="fullName">Full Name</label>
            <span class="ic-icon icon-user"></span>
            <input
              class="form-control"
              placeholder="Enter full name"
              type="text"
              id="fullName"
              formControlName="fullName"
            />
            <span
              class="error-msg"
              *ngIf="
                (isRegsubmitted || f.fullName.touched) && f.fullName.invalid
              "
            >
              {{ showErrorService.showError('fullName', f.fullName.errors) }}
            </span>
          </div>

          <div
            style="margin-bottom: 7px !important"
            class="form-group input-icon"
          >
            <label class="sr-only" for="email">Email</label>
            <span class="ic-icon icon-email"></span>
            <input
              class="form-control"
              placeholder="Email"
              type="text"
              id="email"
              autocomplete="off"
              formControlName="email"
              (keydown)="restrictKeyPressService.RestrictSpace($event)"
            />
            <small class="text-muted ml-1"
              >Please provide a valid healthcare email address</small
            >
            <span
              class="error-msg"
              *ngIf="(isRegsubmitted || f.email.touched) && f.email.invalid"
            >
              {{ showErrorService.showError('email', f.email.errors) }}
            </span>
          </div>
          <div class="form-group input-icon">
            <label class="sr-only" for="password">Password</label>
            <span class="ic-icon icon-password"></span>
            <input
              class="form-control"
              placeholder="Password"
              type="{{ signUpPasswordStatus ? 'text' : 'password' }}"
              id="password"
              formControlName="password"
            />
            <span
              (click)="signUpPasswordEvent('password')"
              [ngClass]="
                signUpPasswordStatus ? 'icon-passshow' : 'icon-passhide'
              "
              class="pass-icon"
            ></span>
            <span
              class="error-msg"
              *ngIf="
                (isRegsubmitted || f.password.touched) && f.password.invalid
              "
            >
              {{ showErrorService.showError('password', f.password.errors) }}
            </span>
          </div>
          <div class="form-group input-icon">
            <label class="sr-only" for="confirmPassword"
              >Confirm Password</label
            >
            <span class="ic-icon icon-password"></span>
            <input
              class="form-control"
              placeholder="Confirm password"
              type="{{ signUpChangePasswordStatus ? 'text' : 'password' }}"
              id="confirmpassword"
              formControlName="confirmPassword"
            />
            <span
              (click)="signUpPasswordEvent('confirmPassword')"
              [ngClass]="
                signUpChangePasswordStatus ? 'icon-passshow' : 'icon-passhide'
              "
              class="pass-icon"
            ></span>
            <span
              class="error-msg"
              *ngIf="
                (isRegsubmitted || f.confirmPassword.touched) &&
                f.confirmPassword.invalid
              "
            >
              {{
                showErrorService.showError(
                  'confirmPassword',
                  f.confirmPassword.errors
                )
              }}
            </span>
            <span
              class="error-msg"
              *ngIf="
                registerForm.errors?.mismatch &&
                (registerForm.controls['password'].dirty ||
                  registerForm.controls['confirmPassword'].touched)
              "
            >
              Passwords don't match.
            </span>
          </div>
          <div class="custom-control custom-checkbox my-4">
            <input
              type="checkbox"
              class="ccheck custom-control-input"
              id="agreeTermsAndConditions"
              formControlName="agreeTermsAndConditions"
            />
            <label
              class="custom-control-label"
              for="agreeTermsAndConditions"
            ></label>

            <span
              >By signing up, you agree to our
              <a [routerLink]="[URLConstants.TERMSANDCONDITION]"
                >Terms & Conditions</a
              >, and
              <a [routerLink]="[URLConstants.PRIVACYPOLICY]">Privacy Policy </a
              >.</span
            >
            <span
              class="error-msg"
              *ngIf="
                (isRegsubmitted || f.agreeTermsAndConditions.touched) &&
                f.agreeTermsAndConditions.invalid
              "
            >
              {{
                showErrorService.showError(
                  'agreeTermsAndConditions',
                  f.agreeTermsAndConditions.errors
                )
              }}
            </span>
          </div>
          <div class="btn-box text-center">
            <button type="submit" class="large-btn btn-primary">Submit</button>
          </div>
          <div class="signin-block text-center mt-2 mt-md-4">
            <p>
              Already have an account?<a
                href="javascript:void(0)"
                class="forgotpass pl-1"
                (click)="openModal(template); modalRef2.hide()"
                >Sign In.</a
              >
            </p>
          </div>
          <!-- <div class="signin-block text-center mt-2 mt-md-3">
            <a
              href="javascript:void(0)"
              class="forgotpass pl-1"
              [adaptivePosition]="false"
              placement="left"
              tooltip="eg. albertahealth.ca"
              (click)="openModal4(domain); modalRef2.hide()"
              >Recommend New Domain</a
            >
          </div> -->
        </form>
      </div>
    </div>
  </ng-template>
</div>
<!-- Sign Up -->

<div class="custom-model">
  <ng-template #template8>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="openModal2(template2); modalRef8.hide()"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="container d-flex align-content-center justify-content-center">
        <div class="pt-2 pb-4 rounded-lg text-center">
          <div
            class="offset-md-1 col-lg-10 col-md-10 col-sm-12 text-center"
            style="margin: auto"
          >
            <a class="navbar-brand logo" href="#">
              <img
                width="152"
                height="41"
                src="assets/images/updated_Logo.svg"
                alt="no-img"
              />
            </a>
            <p class="mt-5 qr-text">
              {{ 'You could also register with our mobile app.' | translate }}
            </p>

            <div class="img-block mt-5">
              <a href="https://apps.apple.com/us/app/dicerra/id1632367935">
                <img
                  width="164"
                  height="56"
                  class="playstore"
                  src="assets/images/app-store.png"
                  alt="Available At Ios Store"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dicerra.med"
              >
                <img
                  width="164"
                  height="56"
                  class="appstore"
                  src="assets/images/play-store.png"
                  alt="Available At Play Store"
                />
              </a>
            </div>
            <hr />
            <button
              class="large-btn btn-primary mt-20 mb-5"
              (click)="openModal2(template2); modalRef8.hide()"
            >
              {{ 'Or Continue Here' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
<!-- redirect modal-->

<div class="custom-model">
  <ng-template #forgotpass>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef3.hide(); submitted = false"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="common-form text-center">
        <h2 class="text-center form-title">
          {{ 'Forgot Password' | translate }}
        </h2>
        <img
          class="forgot-img"
          src="assets/images/forgotpass.svg"
          alt="Forgot Password"
        />
        <p class="my-4">
          {{ 'Please enter your email address to' | translate }} <br />{{
            'request a password reset.' | translate
          }}
        </p>
        <form [formGroup]="forgetEmailForm" (ngSubmit)="OnForgetEmailSubmit()">
          <div class="form-group input-icon">
            <label class="sr-only" for="signup">{{
              'signup' | translate
            }}</label>
            <span class="ic-icon icon-email"></span>
            <input
              class="form-control"
              placeholder="{{ 'Email' | translate }}"
              type="email"
              id="signup"
              formControlName="forgetEmail"
            />
            <span
              class="error-msg mt-2"
              *ngIf="
                (submitted || forgetEmailForm.controls.forgetEmail.touched) &&
                forgetEmailForm.controls.forgetEmail.invalid
              "
            >
              {{
                showErrorService.showError(
                  'forgetEmail',
                  forgetEmailForm.controls.forgetEmail.errors
                )
              }}
            </span>
          </div>
          <div class="btn-box text-center">
            <button type="submit" class="large-btn btn-primary">
              {{ 'Submit' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
<!-- Forgot password -->
<div class="custom-model">
  <ng-template #domain>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef4.hide(); isRecommantedsubmitted = false"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="common-form text-center">
        <h2 class="text-center form-title mb-4 pb-md-1">Recommend Domain</h2>
        <form
          [formGroup]="recommendDomain"
          (ngSubmit)="recommendDomainOnSubmit()"
        >
          <div class="domainsection form-group input-icon domain-input">
            <label class="sr-only" for="domain">Domain</label>
            <span class="ic-icon icon-globale"></span>
            <input
              class="form-control"
              placeholder="e.g. albertahealth.ca"
              type="text"
              id="domain"
              formControlName="userRecommendDomain"
            />
            <div class="dropdown">
              <a
                class="dropdown-toggle"
                (click)="domainlist(count)"
                type="button"
                data-toggle="dropdown"
              >
                <span class="caret"></span
              ></a>
              <ul class="dropdown-menu newdrop">
                <li *ngFor="let item of domainlists">
                  <a href="#">{{ item.name }}</a>
                </li>
              </ul>
            </div>
          </div>
          <span
            class="error-msg"
            *ngIf="
              (isRecommantedsubmitted ||
                recommendDomain.controls.userRecommendDomain.touched) &&
              recommendDomain.controls.userRecommendDomain.invalid
            "
          >
            {{
              showErrorService.showError(
                'userRecommendDomain',
                recommendDomain.controls.userRecommendDomain.errors
              )
            }}
          </span>
          <div
            class="domainsection form-group input-icon domain-input mt-2"
            style="margin-right: 10px"
          >
            <label class="sr-only" for="domain">Domain Name</label>
            <span
              class="icon-edit-profile"
              style="position: absolute; left: 23px; top: 12px; font-size: 20px"
            ></span>
            <input
              class="form-control"
              placeholder="Domain Name"
              type="text"
              id="domainName"
              formControlName="domainName"
            />
          </div>
          <div class="btn-box text-center mt-4 pt-2">
            <button type="submit" class="large-btn btn-primary">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
<!-- Recommeded Domain -->

<div class="custom-model">
  <ng-template #verified>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="closeOTPModel()"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="common-form text-center">
        <h2 class="text-center form-title">
          {{ 'Two Factor Authentication' | translate }}
        </h2>
        <img
          class="forgot-img"
          src="assets/images/verified.svg"
          alt="Forgot Password"
        />
        <p class="mb-0 mt-3 mt-md-4">
          {{ 'Input the code which we sent you on' | translate }}
        </p>
        <a class="mb-md-4 mb-3 d-inline-block" href="javascript:;">{{
          userEmail ? userEmail : 'john.doe@gmail.com.'
        }}</a>

        <form [formGroup]="OTPForm" (ngSubmit)="onOTPSubmit(ngOtpInput)">
          <ng-otp-input
            #ngOtpInput
            [config]="config"
            (onInputChange)="onOtpChange($event)"
          >
          </ng-otp-input>
          <div class="otp-time-out text-center my-4" *ngIf="!isResendOTP">
            <span class="text-light-black" id="countTimeId"> </span>
          </div>

          <div
            class="signin-block text-center mt-2 mt-md-3 mb-3"
            *ngIf="isResendOTP"
          >
            <a
              class="text-capitalize font-bold"
              href="javascript:;"
              id="resendOTP"
              (click)="resendOTP()"
              >{{ 'Resend OTP' | translate }}</a
            >
          </div>

          <div class="btn-box text-center mb-md-4 mb-3">
            <button
              type="submit"
              class="large-btn btn-primary"
              [disabled]="isTimeOut"
            >
              {{ 'Submit' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </ng-template>
</div>
<!-- Verified -->
<div class="custom-model">
  <ng-template #msg>
    <div class="modal-body-wrapper">
      <button
        type="button"
        class="close pull-right"
        aria-label="Close"
        (click)="modalRef6.hide()"
      >
        <span aria-hidden="true">×</span>
      </button>
      <div class="common-form text-center">
        <p class="text-center form-title mb-4 pb-md-1 text-danger">
          {{ MsgError }}
        </p>
      </div>
    </div>
  </ng-template>
</div>
<!-- Filter options -->
<div class="custom-model">
  <ng-template #filter>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRef7.hide(); isFilterSubmitted = false"
    >
      <span aria-hidden="true">×</span>
    </button>

    <!-- <form>
      <div class="container">
        <div
          class="row"
          [ngClass]="industry == null ? 'justify-content-center mb-5' : 'mb-3 '"
        >
          <div class="col-lg-3 col-sm-12 col-md-6 col-xl-3 mt-2">
            <div>
              <h4 class="mb-3 mt-md-2">{{ 'Industry' | translate }}</h4>
              <ng-select
                class="custom-slectmenu"
                bindLabel="name"
                bindValue="value"
                placeholder="{{ 'Select' | translate }} {{
                  'Industry' | translate
                }}"
                [ngModelOptions]="{ standalone: true }"
                [(ngModel)]="industry"
                (change)="setIndustry('industry', $event)"
                [items]="industryArr"
                [clearable]="false"
              >
              </ng-select>
            </div>
          </div>
        </div>
      </div>
    </form> -->

    <!-- <hr class="w-80 mx-2" /> -->

    <form
      *ngIf="industry === 'healthcare'"
      [formGroup]="filterForm"
      (ngSubmit)="filterSubmit(false)"
    >
      <div class="container">
        <div class="row">
          <div class="col mt-2">
            <h4 class="mb-3 mt-md-2">{{ 'Country' | translate }}</h4>
            <ng-select
              class="custom-slectmenu"
              bindLabel="name"
              bindValue="name"
              placeholder="{{ 'Select' | translate }} {{
                'Country' | translate
              }}"
              [ngModelOptions]="{ standalone: true }"
              (change)="setValueForSelect('country', $event)"
              (clear)="resetSelection('country')"
              formControlName="country"
              [items]="countries"
            >
            </ng-select>
          </div>
          <div class="col mt-2">
            <h4 class="mb-3 mt-md-2">{{ 'Region' | translate }}</h4>
            <ng-select
              class="custom-slectmenu"
              bindLabel="name"
              bindValue="name"
              placeholder="{{ 'Select' | translate }} {{
                'Region' | translate
              }}"
              [ngModelOptions]="{ standalone: true }"
              [items]="regionList"
              (change)="setValueForSelect('region', $event)"
              (clear)="resetSelection('region')"
              formControlName="region"
            >
              >
            </ng-select>
          </div>
          <div class="col mt-2">
            <h4 class="mb-3 mt-md-2">{{ 'Sub-region' | translate }}</h4>
            <ng-select
              class="custom-slectmenu"
              bindLabel="name"
              bindValue="name"
              placeholder="{{ 'Select' | translate }} {{
                'Sub-region' | translate
              }}"
              [ngModelOptions]="{ standalone: true }"
              [items]="subRegionList"
              (change)="setValueForSelect('subRegion', $event)"
              (clear)="resetSelection('subRegion')"
              formControlName="subRegion"
            >
            </ng-select>
          </div>
          <div class="col mt-2">
            <h4 class="mb-3 mt-md-2">{{ 'Hospital' | translate }}</h4>
            <input
              type="text"
              class="filter-hospital form-control"
              id="hospital"
              placeholder="{{ 'Enter' | translate }} {{
                'Hospital' | translate
              }}"
              formControlName="hospitalName"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <h4 class="mb-3 mt-2">{{ 'Department/Specialty' | translate }}</h4>
            <ng-select
              class="custom-slectmenu with-tag"
              bindLabel="name"
              bindValue="name"
              placeholder="{{ 'Select' | translate }} {{
                'Department' | translate
              }}"
              [ngModelOptions]="{ standalone: true }"
              [items]="deparmentList"
              (change)="setValueForSelect('department', $event)"
              (clear)="resetSelection('department')"
              formControlName="department"
              [multiple]="true"
            >
            </ng-select>
          </div>
          <div class="col">
            <h4 class="mb-3 mt-2">{{ 'Tags' | translate }}</h4>
            <ng-select
              class="custom-slectmenu with-tag"
              bindLabel="name"
              bindValue="name"
              placeholder="{{ 'Select' | translate }} {{ 'Tags' | translate }}"
              [ngModelOptions]="{ standalone: true }"
              [items]="tagListArray"
              (clear)="resetSelection('procedures')"
              formControlName="procedures"
              [multiple]="true"
            >
            </ng-select>
          </div>
          <div class="col mt-xs-2">
            <h4 class="mb-3 mt-2">{{ 'Medication Error' | translate }}</h4>
            <ng-select
              class="custom-slectmenu"
              [clearable]="true"
              placeholder="{{ 'Select' | translate }} {{
                'Medication Error' | translate
              }}"
              [ngModelOptions]="{ standalone: true }"
              [items]="medicationErrors"
              (change)="setValueForSelect('medicationeError', $event)"
              (clear)="resetSelection('medicationeError')"
              bindLabel="name"
              bindValue="name"
              formControlName="medicationeError"
            >
            </ng-select>
          </div>
        </div>
        <div class="btn-box text-right mt-4 mb-5">
          <button type="submit" class="large-btn mt-2 btn-primary">
            {{ 'Apply & Save Filters' | translate }}
          </button>
          <button
            (click)="
              resetFilterData(); modalRef7.hide(); isFilterSubmitted = false
            "
            type="submit"
            class="large-btn btn-transparent ml-3 btn-primary mt-2"
          >
            {{ 'Reset Filters' | translate }}
          </button>
        </div>
      </div>
    </form>

    <form
      *ngIf="industry === 'aviation'"
      [formGroup]="filterFormAviation"
      (ngSubmit)="filterSubmitAviation(false)"
    >
      <div class="container">
        <div class="row">
          <div class="col mt-2">
            <h4 class="mb-3 mt-md-2">{{ 'Country' | translate }}</h4>
            <ng-select
              class="custom-slectmenu"
              bindLabel="name"
              bindValue="name"
              placeholder="{{ 'Select' | translate }} {{
                'Country' | translate
              }}"
              [ngModelOptions]="{ standalone: true }"
              (change)="setValueForSelect('country', $event)"
              (clear)="resetSelection('country')"
              formControlName="country"
              [items]="countryList"
            >
            </ng-select>
          </div>
          <div class="col mt-2">
            <h4 class="mb-3 mt-md-2" for="name">
              {{ 'Select' | translate }} {{ 'Type' | translate }}
            </h4>
            <ng-select
              class="custom-slectmenu"
              [items]="typesArr"
              bindLabel="name"
              bindValue="name"
              formControlName="type"
              placeholder="{{ 'Select' | translate }} {{ 'Type' | translate }}"
              [clearable]="true"
              [ngModelOptions]="{ standalone: true }"
              (change)="setValueForSelect('type', $event)"
              (clear)="resetSelection('type')"
            >
            </ng-select>
          </div>

          <div class="col mt-2">
            <h4 class="mb-3 mt-md-2">
              {{ 'Airport or airstrip' | translate }}
            </h4>
            <ng-select
              class="custom-slectmenu"
              [items]="airportListArray | async"
              placeholder="{{ 'Airport or airstrip' | translate }}"
              [clearable]="true"
              [dropdownPosition]="'auto'"
              [closeOnSelect]="true"
              [typeaheadMinLength]="10000000"
              [typeahead]="typeAheadSource"
              formControlName="airstrip"
              [notFoundText]="'Data not found'"
              bindLabel="name"
              bindValue="name"
              [ngModelOptions]="{ standalone: true }"
              (change)="setValueForAirport($event)"
            >
            </ng-select>
          </div>
          <div
            *ngIf="
              otherAirportSelected ||
              filterFormAviation.value.airstrip === 'Other Airport'
            "
            class="col mt-2"
          >
            <h4 class="mb-3 mt-md-2">
              {{ 'Other Airport or airstrip' | translate }}
            </h4>
            <input
              type="text"
              class="filter-hospital form-control"
              id="hospital"
              placeholder="{{ 'Other Airport or airstrip' | translate }}"
              formControlName="otherAirstrip"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="col">
            <h4 class="mb-3 mt-2">{{ 'Expertise' | translate }}</h4>
            <ng-select
              class="custom-slectmenu"
              bindLabel="name"
              bindValue="name"
              placeholder="{{ 'Select' | translate }} {{
                'Expertise' | translate
              }}"
              [ngModelOptions]="{ standalone: true }"
              [items]="expertiseList"
              (change)="setValueForSelect('expertise', $event)"
              (clear)="resetSelection('expertise')"
              formControlName="expertise"
            >
            </ng-select>
          </div>
          <!-- <div class="col">
            <h4 class="mb-3 mt-2">Place of Service</h4>
            <ng-select
              class="custom-slectmenu"
              [clearable]="true"
              placeholder="Select Place of Service"
              [ngModelOptions]="{ standalone: true }"
              [items]="placeOfServiceList"
              (change)="setValueForSelect('placeOfService', $event)"
              (clear)="resetSelection('placeOfService')"
              bindLabel="name"
              bindValue="name"
              formControlName="placeOfService"
            >
            </ng-select>
          </div> -->
          <div *ngIf="typeSelected || airlineSelected" class="col">
            <h4 class="mb-3 mt-2">{{ 'Airline' | translate }}</h4>
            <ng-select
              class="custom-slectmenu"
              [clearable]="true"
              placeholder="{{ 'Select' | translate }} {{
                'Airline' | translate
              }}"
              [ngModelOptions]="{ standalone: true }"
              [items]="airlineList"
              (change)="setValueForSelect('airline', $event)"
              (clear)="resetSelection('airline')"
              bindLabel="name"
              bindValue="name"
              formControlName="airline"
            >
            </ng-select>
          </div>
          <div *ngIf="typeSelected || privateOrCommercialSelected" class="col">
            <h4 class="mb-3 mt-2">{{ 'Region' | translate }}</h4>
            <ng-select
              class="custom-slectmenu"
              bindLabel="name"
              bindValue="name"
              placeholder="{{ 'Select' | translate }} {{
                'Region' | translate
              }}"
              [ngModelOptions]="{ standalone: true }"
              [items]="aviationRegionList"
              (change)="setValueForSelect('region', $event)"
              (clear)="resetSelection('region')"
              formControlName="region"
            >
              >
            </ng-select>
          </div>
          <div class="col mt-xs-2">
            <h4 class="mb-3 mt-2">{{ 'Tags' | translate }}</h4>
            <ng-select
              class="custom-slectmenu with-tag"
              bindLabel="name"
              bindValue="name"
              placeholder="{{ 'Select' | translate }} {{ 'Tags' | translate }}"
              [ngModelOptions]="{ standalone: true }"
              [items]="tagListArray"
              (clear)="resetSelection('procedures')"
              formControlName="procedures"
              [multiple]="true"
            >
            </ng-select>
          </div>
        </div>
        <div class="btn-box text-right mt-4 mb-5">
          <button type="submit" class="large-btn mt-2 btn-primary">
            {{ 'Apply & Save Filters' | translate }}
          </button>
          <button
            (click)="
              resetFilterData(); modalRef7.hide(); isFilterSubmitted = false
            "
            type="submit"
            class="large-btn btn-transparent ml-3 btn-primary mt-2"
          >
            {{ 'Reset Filters' | translate }}
          </button>
        </div>
      </div>
    </form>
  </ng-template>
</div>
