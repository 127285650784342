export const URLConstants = {
  HOMEPAGE: '/home',
  ABOUT: '/about',
  BLOG: '/blog',
  SERVICE: '/service',
  CONTACT: '/contact',
  LOGIN: '/login',
  REGISTER: '/register',
  TERMSANDCONDITION: '/terms-conditions',
  PRIVACYPOLICY: '/privacy-policy',
  NOTIFICATION: '/notification',
  FAQS: '/faqs',
  MYPROFILE: '/myprofile',
  EDITPROFILE: '/editprofile',
  EDITPROFILEINSTITUTION: '/editprofileinstitution',
  SETTINGS: '/settings',
  MYCONTRIBUTION: '/mycontribution',
  FEED: '/feed',
  RESETPASSWORD: '/reset-password',
  CREATEINCIDENT: '/incident',
  CREATECHEOINCIDENT: '/cheo-incident',
  CREATEAVIATIONINCIDENT: '/incident-aviation',
  FAVOURITE: '/favourite',
  MYSUBMISSIONS: '/mysubmissions',
  MYINVESTIGATIONS: '/myinvestigations',
  MYASSIGNMENTS: '/myassignments',
  CREATEBRUYEREINCIDENT: '/bruyere-incident',

  //perley
  CREATEPERLEYINCIDENT: '/perley-incident',
};
