import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';

// Auth Guard
import {
  CanLoginActivate,
  CanAuthActivate,
  HealthcareRouteGuard,
  AviationRouteGuard,
  CheoRouteGuard,
  BruyereRouteGuard,
  PerleyRouteGuard,
} from './common/services/TS-files/auth.gaurd';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'home',
    loadChildren: () =>
      import('./public/home-page/home-page.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: 'about',
    loadChildren: () =>
      import('./public/about/about.module').then((m) => m.AboutModule),
  },
  {
    path: 'links',
    loadChildren: () =>
      import('./public/qr-page/qr-page.module').then((m) => m.QrPageModule),
  },
  {
    path: 'contact',
    loadChildren: () =>
      import('./public/contact/contact.module').then((m) => m.ContactModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./public/resetpassword/resetpassword.module').then(
        (m) => m.ResetpasswordModule
      ),
    canActivate: [CanLoginActivate],
  },
  {
    path: 'signup-onboard',
    loadChildren: () =>
      import('./modules/signuponboarding/signuponboarding.module').then(
        (m) => m.SignuponboardingModule
      ),
    canActivate: [CanLoginActivate],
  },
  {
    path: 'signup-onboard-institution',
    loadChildren: () =>
      import(
        './modules/signuponboardinginstitution/signuponboardinginstitution.module'
      ).then((m) => m.SignuponboardingInstitutionModule),
    canActivate: [CanLoginActivate],
  },
  {
    path: 'feed',
    loadChildren: () =>
      import('./modules/feeds/feeds.module').then((m) => m.FeedsModule),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'favourite',
    loadChildren: () =>
      import('./modules/feeds/feeds.module').then((m) => m.FeedsModule),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'terms-conditions',
    loadChildren: () =>
      import('./public/terms-conditions/terms-conditions.module').then(
        (m) => m.TermsConditionsModule
      ),
  },
  {
    path: 'privacy-policy',
    loadChildren: () =>
      import('./public/privacy-policy/privacy-policy.module').then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: 'faqs',
    loadChildren: () =>
      import('./public/faqs/faqs.module').then((m) => m.FaqsModule),
  },
  {
    path: 'comments/:id',
    loadChildren: () =>
      import('./modules/comments/comments.module').then(
        (m) => m.CommentsModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'notification',
    loadChildren: () =>
      import('./modules/notification/notification.module').then(
        (m) => m.NotificationModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'incident',
    loadChildren: () =>
      import('./modules/incident/incident.module').then(
        (m) => m.IncidentModule
      ),
    canActivate: [CanAuthActivate, HealthcareRouteGuard],
  },
  {
    path: 'cheo-incident',
    loadChildren: () =>
      import('./modules/cheo incident/cheoincident.module').then(
        (m) => m.CheoIncidentModule
      ),
    canActivate: [CanAuthActivate, HealthcareRouteGuard, CheoRouteGuard],
  },
  {
    path: 'bruyere-incident',
    loadChildren: () =>
      import('./modules/bruyereincident/bruyereincident.module').then(
        (m) => m.BruyereincidentModule
      ),
    canActivate: [CanAuthActivate, HealthcareRouteGuard, BruyereRouteGuard],
  },
  {
    path: 'bruyere-incidentreview',
    loadChildren: () =>
      import(
        './modules/bruyereincidentreview/bruyereincidentreview.module'
      ).then((m) => m.BruyereIncidentReviewModule),
    canActivate: [CanAuthActivate, HealthcareRouteGuard, BruyereRouteGuard],
  },
  {
    path: 'edit-bruyere-incident',
    loadChildren: () =>
      import('./modules/bruyereincidentedit/editbruyereincident.module').then(
        (m) => m.EditBruyereIncidentModule
      ),
    canActivate: [CanAuthActivate, HealthcareRouteGuard, BruyereRouteGuard],
  },
  {
    path: 'edit-cheo-incident',
    loadChildren: () =>
      import('./modules/cheo incident edit/editcheoincident.module').then(
        (m) => m.EditCheoIncidentModule
      ),
    canActivate: [CanAuthActivate, HealthcareRouteGuard, CheoRouteGuard],
  },
  //perley
  {
    path: 'perley-incident',
    loadChildren: () =>
      import('./modules/perleyincident/perleyincident.module').then(
        (m) => m.PerleyincidentModule
      ),
    canActivate: [CanAuthActivate, HealthcareRouteGuard, PerleyRouteGuard],
  },
  {
    path: 'perley-incidentreview',
    loadChildren: () =>
      import('./modules/perleyincidentreview/perleyincidentreview.module').then(
        (m) => m.PerleyIncidentReviewModule
      ),
    canActivate: [CanAuthActivate, HealthcareRouteGuard, PerleyRouteGuard],
  },
  {
    path: 'edit-perley-incident',
    loadChildren: () =>
      import('./modules/perleyincidentedit/editperleyincident.module').then(
        (m) => m.EditPerleyIncidentModule
      ),
    canActivate: [CanAuthActivate, HealthcareRouteGuard, PerleyRouteGuard],
  },

  {
    path: 'incident/:id',
    loadChildren: () =>
      import('./modules/incident/incident.module').then(
        (m) => m.IncidentModule
      ),
    canActivate: [CanAuthActivate, HealthcareRouteGuard],
  },
  {
    path: 'incident-aviation',
    loadChildren: () =>
      import('./modules/aviationincident/aviationincident.module').then(
        (m) => m.AviationincidentModule
      ),
    canActivate: [CanAuthActivate, AviationRouteGuard],
  },
  {
    path: 'incident-aviation/:id',
    loadChildren: () =>
      import('./modules/aviationincident/aviationincident.module').then(
        (m) => m.AviationincidentModule
      ),
    canActivate: [CanAuthActivate, AviationRouteGuard],
  },
  {
    path: 'myprofile',
    loadChildren: () =>
      import('./modules/myprofile/myprofile.module').then(
        (m) => m.MyprofileModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'editprofile',
    loadChildren: () =>
      import('./modules/editprofile/editprofile.module').then(
        (m) => m.EditprofileModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'editprofileinstitution',
    loadChildren: () =>
      import(
        './modules/editprofileinstitution/editprofileinstitution.module'
      ).then((m) => m.EditprofileInstitutionModule),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'detailpage/:id',
    loadChildren: () =>
      import('./modules/detailpage/detailpage.module').then(
        (m) => m.DetailpageModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'detailsolution/:id1/:id2',
    loadChildren: () =>
      import('./modules/detailsolution/detailsolution.module').then(
        (m) => m.DetailsolutionModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'mycontribution',
    loadChildren: () =>
      import('./modules/mycontribution/mycontribution.module').then(
        (m) => m.MycontributionModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'mysubmissions',
    loadChildren: () =>
      import('./modules/mysubmissions/mysubmissions.module').then(
        (m) => m.MysubmissionsModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'myinvestigations',
    loadChildren: () =>
      import('./modules/myinvestigations/myinvestigations.module').then(
        (m) => m.MyinvestigationsModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'myassignments',
    loadChildren: () =>
      import('./modules/myassignments/myassignments.module').then(
        (m) => m.MyinvestigationsModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'cheoincidentreview',
    loadChildren: () =>
      import('./modules/cheoincidentreview/cheoincidentreview.module').then(
        (m) => m.CheoIncidentReviewModule
      ),
    canActivate: [CanAuthActivate, CheoRouteGuard],
  },
  {
    path: 'flightinfotool',
    loadChildren: () =>
      import('./modules/flightinfotool/flightinfotool.module').then(
        (m) => m.FlightinfotoolModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'news',
    loadChildren: () =>
      import('./modules/news/news.module').then((m) => m.NewsModule),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'essentialtools',
    loadChildren: () =>
      import('./modules/essentialtools/essentialtools.module').then(
        (m) => m.EssentialtoolsModule
      ),
    canActivate: [CanAuthActivate],
  },
  {
    path: 'blog',
    loadChildren: () =>
      import('./public/blog/blog.module').then((m) => m.BlogModule),
  },
  {
    path: 'viewblog/:id',
    loadChildren: () =>
      import('./public/view-blog/view-blog.module').then(
        (m) => m.ViewBlogModule
      ),
  },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
  providers: [
    CanLoginActivate,
    CanAuthActivate,
    HealthcareRouteGuard,
    AviationRouteGuard,
    CheoRouteGuard,
    BruyereRouteGuard,
    PerleyRouteGuard,
  ],
})
export class AppRoutingModule {}
