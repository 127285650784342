import { Component, OnInit, TemplateRef } from '@angular/core';
import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import * as _ from 'lodash';
import { RestrictKeyPressService } from '../../common/services/service/restrict-key-press.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
// Constants
import { callAPIConstants } from '../../common/constants/callAPI-constants';
import { URLConstants } from '../../common/constants/routerLink-constants';

// Service
import { CommonService } from '../../common/services/service/common.service';
import { ShowErrorService } from '../../common/services/service/show-error.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router, ActivatedRoute } from '@angular/router';
import { LocalStorageService } from 'src/app/common/services/service/local-storage.service';
import { CloseModalService } from '../../common/services/service/close-modal.service';
import { GlobalLanguageService } from 'src/app/common/services/service/global-language.service';
import { GlobalFilterService } from 'src/app/common/services/service/global-filter.service';

@Component({
  selector: 'app-signuponboardinginstitution',
  templateUrl: './signuponboardinginstitution.component.html',
  styles: [],
})
export class SignuponboardinginstitutionComponent implements OnInit {
  public resetForm: FormGroup;
  public signupForm: FormGroup;
  public submitted: boolean = false;
  public callAPIConstants = callAPIConstants;
  public URLConstants = URLConstants;
  public signupToken: any;
  public passstatus: boolean = false;
  public confirmPasswordStatus: boolean = false;
  public hideImage: Boolean = true;
  public showGetSetup: Boolean = false;
  public showSetPassword: Boolean = true;
  public regionData: any;
  public healthcareArr: Array<any> = [];
  public deparmentArr: Array<any> = [
    {
      department: '1CallClick',
      subDepartment: ['Intake', 'Mental Health Addiction Nurse'],
    },
    {
      department: 'Ambulatory Care',
      subDepartment: [
        'Adolescent Medicine',
        'Bank Street clinic (including CHAL)',
        'Call Center',
        'Cardiology / CV Surgery',
        'Centre for Healthy Active Living (CHAL)',
        'Dental clinic',
        'Dermatology',
        'Dialysis',
        'Endocrinology',
        'Gastroenterology',
        'General Surgery',
        'Gender Diversity',
        'Gynecology',
        'Infectious Diseases',
        'Metabolics',
        'Nephrology',
        'Neurology / Neurophysiology',
        'Neurosurgery',
        'Ophthalmology',
        'Orthopedics',
        'Otolaryngology Head and Neck Surgery / ENT',
        'Pediatric Medicine',
        'Plastic Surgery',
        'Pre-assessment Clinic',
        'Respirology',
        'Rheumatology',
        'Sleep lab',
        'Social Work',
        'Urology',
      ],
    },
    {
      department: 'Child Development & Rehab',
      subDepartment: [
        'Access Team',
        'Audiology / AVT',
        'Behavioural Services',
        'Blind Low Vision - option removed',
        'Child Life / Therapeutic Clown',
        'Chronic Pain Service',
        'Clinic for Augmentative Communication',
        'Community Preschool Team',
        'Cornwall Team',
        'Developmental Pediatrics/Diagnostic Hub',
        'FASD Assessment Clinic?',
        'Infant & Child Development Service',
        'Infant Hearing Program?',
        'Janice St Clair - Community Preschool and PT - Cornwall changed to Janice above',
        'Kanata SLP, OT, PT & Admin (CTC)',
        'Neonatal Follow Up Clinic',
        'Outpatient Dietician Clinic',
        'Preschool',
        'Preschool Speech & Language / First Words',
        'Psychology (CTC)',
        'Recreation Therapy',
        'Rehab Therapy Services',
        'Renfrew Team',
        'Research',
        'Respite',
        'Seating & Mobility',
        'Smyth Physiotherapy (CTC)',
        'Smyth SLP & OT (CTC)',
        'Social Work (CTC)',
        'Specialty Medical Clinics',
        'Technical Services',
        'Social Work / FRW',
      ],
    },
    {
      department: 'Critical Care & Surgical Services',
      subDepartment: [
        'Acute Pain Services',
        'CHEO Transport Team',
        'CV Surgery Program',
        'Day Care Surgery',
        'Inpatient Surgical - 4W',
        'Neonatal Transport Team',
        'NICU',
        'OR',
        'Perioperative Clinic',
        'PICU',
        'PICU 2',
        'Respiratory Therapy',
        'SPOT',
      ],
    },
    {
      department: 'Emergency Services',
      subDepartment: [
        'Abuse and Trauma (Protocol)',
        'Emergency Department',
        'Patient Registration',
        'Sexual Assault',
      ],
    },
    {
      department: 'EORLA Laboratories',
      subDepartment: [
        'Electron Microscopy',
        'Microbiology',
        'Pathology Lab',
        'Patient Services Office',
        'Point of Care Testing',
        'RRU - Biochemistry',
        'RRU - Central Lab Receiving (CLR)',
        'RRU - Hematology',
        'RRU - Transfusion Medicine',
        'Virology',
      ],
    },
    {
      department: 'Genetics Program',
      subDepartment: [
        'Clinic',
        'Lab Administration',
        'Lab Receiving',
        'Laboratory',
        'Genetic Clinic',
        'Genetic Diagnostic Laboratory',
      ],
    },
    {
      department: 'Hematology & Oncology',
      subDepartment: [
        '4N',
        'MDU',
        'MDU - Day treatment',
        'MDU - Dialysis',
        'MDU - Hematology',
        'MDU - Oncology',
      ],
    },
    {
      department: 'Integrated Care - Home and Community Care',
      subDepartment: [
        'Community Discharge Team',
        'Complex Care Team',
        'Coordinated Service Planning',
        'Home & Community Care',
        'Kids Come First Clinic',
        'Navigator Program',
        'Rapid Response Nursing',
        'School Based Rehab Services',
        'School Health Services',
        'SPA / Kids Come First Clinic',
      ],
    },
    {
      department: 'Medical Imaging',
      subDepartment: [
        'CT',
        'General Radiography (x - ray)',
        'GI / GU',
        'Interventional / Fluoroscopy',
        'IR / Cath Lab',
        'MRI',
        'Nuclear Medicine',
        'Sedation / Recovery area',
        'Ultrasound',
        'X-ray',
      ],
    },
    {
      department: 'Medical Inpatient Units',
      subDepartment: [
        '4E',
        '5E',
        'IMCU',
        'IMCU (Intermdiate Care Unit)',
        'Inpatient Overflow (MDU)',
      ],
    },
    {
      department: 'Mental Health',
      subDepartment: [
        'Bridges project',
        'Child and Youth Protection',
        'Centralized Intake',
        'Consultation Liaison Team',
        'Crisis Intervention / Psychiatric Emergency',
        'Eating Disorder Day Treatment (Industrial)',
        'Eating Disorders Day Program',
        'Eating Disorders Inpatients - 6N',
        'Eating Disorders Outpatients',
        'Eating Disorders Partial Hospitalization Unit (5N)',
        'Eating Disorders Program (outpatient)',
        'Early Connection',
        'ECHO',
        'Inpatient Mental Health 6E',
        'MH Abuse and Trauma',
        'MH Neuropsychology (6W)',
        'Mental Health Transitional Unit (MHTU)',
        'Outpatient Mental Health',
        'Rapid Response',
        'Support Transitions',
        'Tele Mental Health',
        'Transitional Age Youth',
        'Youthnet',
      ],
    },
    {
      department: 'Neurodevelopmental Health',
      subDepartment: [
        'Behavioural Services, Kanata',
        'Cornwall, Renfrew',
        'DSO-Industrial Site',
        'St Laurent site, Intake, Registration, School',
        'Wrap',
      ],
    },
    {
      department: 'Non - Clinical',
      subDepartment: [
        'Clinical Engineering',
        'Communications',
        'Environmental Services',
        'Facilities Management',
        'Finance',
        'Food Services',
        'Health Records',
        'Human Resources & Labour Relations',
        'IT Services',
        'Laboratory Services',
        'Media House',
        'Occupational Health & Wellness',
        'Organizational Development and Learning',
        'Pharmacy',
        'Planning and Performance',
        'Privacy',
        'Quality and Safety',
        'Research Ethics Board',
        'Retail Operations and Gift Shop',
        'Security',
        'Telecommunications',
        'Volunteers',
      ],
    },
    {
      department: 'Nursing Practice',
      subDepartment: [
        'Corporate Educators',
        'Float nurses',
        'Float team (Nurses & HCAs)',
        'Health Care Aide',
        'Lactation Support',
        'Nurses Specialized in Wound, Ostomy, and Continence (NSWOC) Team',
        'Nursing Students',
        'SIM Program',
        'Vascular Access Team',
      ],
    },
    {
      department: 'Palliative Care Team',
      subDepartment: ['Palliative Care Team'],
    },
    {
      department: 'Patient Experience',
      subDepartment: [
        'Greeter Program',
        'Patient / Family Engagement',
        'Patient / Family Representative',
        'Spiritual / Cultural Support',
      ],
    },
    {
      department: 'Periop Services',
      subDepartment: [
        'Acute Pain Services',
        'Day Care Surgery',
        'Medical Device Reprocessing Department',
        'Offsite Transport',
        'Operating Room',
        'PACU',
        'Pre - Anesthesia Clinic',
        'Surgical Admin Assistants',
      ],
    },
    {
      department: 'Pharmacy',
      subDepartment: [
        'Critical Care Satellite Pharmacy',
        'Hem/Onc Satellite Pharmacy',
        'Main Pharmacy',
        'Retail Pharmacy / KidCare',
      ],
    },
    {
      department: 'Professional Practice',
      subDepartment: ['Dietitian services', 'Outpatient Dietician clinic'],
    },
    {
      department: 'Provincial Programs',
      subDepartment: [
        'BORN',
        'Champlain Maternal Newborn Regional Program',
        'Knowledge Institute',
        'Ontario Newborn Screening Program',
      ],
    },
    {
      department: 'Safety and Family Partnership',
      subDepartment: [
        'Environmental Services',
        'Infection Prevention and Control',
        'Occupational Safety',
        'Patient and Family Experience',
      ],
    },
    {
      department: 'Support Services',
      subDepartment: [
        'Clinical Engineering',
        'Communications',
        'Decision Support',
        'Digital Health',
        'Executive Admin',
        'Facilities Management',
        'Finance',
        'Food Services - Cafeteria',
        'Food Services - Coffee Shop',
        'Gift Shop',
        'Health Records',
        'Human Resources',
        'IT Services',
        'Learning Leadership & Emergency Preparedness',
        'Locating',
        'Materials management',
        'Medical Device Reprocessing Department',
        'Media House',
        'Medical Staff Offices',
        'Occupational Health & Wellness',
        'Organizational Development & Learning',
        'Planning and Performance',
        'Purchasing',
        'Security',
        'Simulation',
        'Volunteer Resources',
      ],
    },
  ];
  public deparmentArrTemp: Array<any> = [];
  public countriesArr: Array<any> = [];
  public aviationCountriesArr: Array<any> = [];
  public regionsArr: Array<any> = [];
  public aviationRegionsArr: Array<any> = [];
  public subregionsArr: Array<any> = [];
  public tempRegionsArr: Array<any> = [];
  public tempAviationRegionsArr: Array<any> = [];
  public tempSubregionsArr: Array<any> = [];
  public carouselData: Array<any> = [1, 2, 3, 4, 5];
  public showingElement = 0;
  public value = 0;
  public onboardingSteps: Array<any> = [];
  public userCountryForm: FormGroup;
  public userRegionsForm: FormGroup;
  public userRegionFormAviation: FormGroup;
  public userDepartmentForm: FormGroup;
  public userExperienceForm: FormGroup;
  public userHospitalForm: FormGroup;
  public countrySubmitted: boolean = false;
  public regionsSubmitted: boolean = false;
  public airlineSubmitted: boolean = false;
  public typeSubmitted: boolean = false;
  public expertiseSubmitted: boolean = false;
  public placeOfServiceSubmitted: boolean = false;
  public experienceSubmitted: boolean = false;
  public departmentSubmitted: boolean = false;
  public onboardingCompleted: boolean = false;
  public showInitialModal: boolean = true;
  public globalSignup: boolean = false;
  public sessionAnalyticsToken: String = null;
  public showIndustrySelection: boolean = true;
  public selectedIndustry: String = '';
  public userIndustry: String = '';
  public userTypeForm: FormGroup;
  public userPlaceOfServiceForm: FormGroup;
  public userAirlineForm: FormGroup;
  public typesArr: Array<any> = [
    'Military',
    'Private',
    'Commercial',
    'Airline',
  ];
  public placeOfServiceArr: Array<any> = [];
  public userExpertiseForm: FormGroup;
  public tempAirlinesArr: Array<any> = [];
  public airlinesArr: Array<any> = [];
  public tempPlaceOfServiceArr: Array<any> = [];
  public expertiseArr: Array<any> = [];
  languageArr = [
    { name: 'English', value: 'en' },
    { name: 'Deutsch', value: 'de' },
    { name: 'French', value: 'fr' },
    // Add more languages if needed
  ];
  public selectedLanguage: any;
  public modalRef: BsModalRef;
  public privacyPlicy: any = {};
  public termsAndConditions: any;
  public ip = '';
  public selectedCountry: String = '';
  public designationArrTemp: Array<any> = [];
  public rolesArrTemp: Array<any> = [];
  public positionArrTemp: Array<any> = [];
  public designationArr: Array<any> = [];
  public positionArr: Array<any> = [];
  public rolesArr: Array<any> = [];
  public selectedDesignation: String = '';
  public positionConditionArray: Array<String> = [
    'Medicine',
    'Nursing',
    'Allied health',
  ];
  public otherDepartmentSelected: Boolean = false;
  public userProfileData: any = {};
  countriesSelected: String[] = [
    'Canada',
    'United States',
    'Australia',
    'New Zealand',
    'United Kingdom',
    'Ireland',
  ];
  public otherSubspecialtySelected: Boolean = false;
  public subspecialtyArr: Array<any> = [];
  public subspecialtyArrTemp: Array<any> = [];
  subspecialSelected: String[] = ['United States', 'Australia'];
  public institution: string = '';
  public industry: any;
  public otherSubdepartmentSelected: Boolean = false;
  public subdepartmentArr: Array<any> = [];
  public subdepartmentArrTemp: Array<any> = [];
  public userPersonalData: any = {};
  constructor(
    private formBuilder: FormBuilder,
    public showErrorService: ShowErrorService,
    private commonService: CommonService,
    private spinnerService: NgxSpinnerService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public localStorageService: LocalStorageService,
    public breakpointObserver: BreakpointObserver,
    public closeModalService: CloseModalService,
    public restrictKeyPressService: RestrictKeyPressService,
    public globalLanguageService: GlobalLanguageService,
    public modalService: BsModalService,
    public GlobalFilterService: GlobalFilterService
  ) {
    this.ip = localStorageService.getToken('ip');
    this.activatedRoute.queryParams.subscribe((params) => {
      this.institution = params['institution'] || '';
      this.industry = params['industry'] || '';
      this.signupToken = params['signup'] ? params['signup'] : '';
      this.signupToken = params['signup'] ? params['signup'] : '';
      this.globalSignup = params['globalSignup']
        ? params['globalSignup']
        : false;
      if (this.globalSignup) {
        this.showIndustrySelection = true;
      } else {
        let industry = params['industry'] ? params['industry'] : '';
        this.localStorageService.setToken('industry', industry);
        this.showIndustrySelection = false;
      }
    });
  }

  ngOnInit(): void {
    // if (this.router.url === '/feed') {
    //   this.showInitialModal = false;
    // }
    this.resetForm = this.formBuilder.group(
      {
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~-]).{8,}$/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validator: this.passwordMatchValidator }
    );

    this.signupForm = this.formBuilder.group(
      {
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$'
            ),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~-]).{8,}$/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
        agreeTermsAndConditions: [false, [Validators.requiredTrue]],
      },
      { validator: this.passwordMatchValidator }
    );

    this.userRegionsForm = this.formBuilder.group({
      region: [null, [Validators.required]],
      subRegion: [null, [Validators.required]],
    });

    this.userCountryForm = this.formBuilder.group({
      country: [null, [Validators.required]],
    });

    this.userDepartmentForm = this.formBuilder.group({
      department: [null, [Validators.required]],
      otherRole: [''],
      otherDepartment: [''],
      role: [null],
      subdepartment: [null],
    });

    this.userExperienceForm = this.formBuilder.group({
      experience: ['', [Validators.required]],
    });

    this.userHospitalForm = this.formBuilder.group({
      hospital: [''],
    });

    this.userAirlineForm = this.formBuilder.group({
      airline: [null, [Validators.required]],
    });

    this.userTypeForm = this.formBuilder.group({
      type: [null, [Validators.required]],
    });

    this.userCountryForm = this.formBuilder.group({
      country: [null, [Validators.required]],
    });

    this.userPlaceOfServiceForm = this.formBuilder.group({
      placeOfService: [null, [Validators.required]],
    });

    this.userExpertiseForm = this.formBuilder.group({
      expertise: [null, [Validators.required]],
    });

    this.userRegionFormAviation = this.formBuilder.group({
      region: [null, [Validators.required]],
    });

    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
      ])
      .subscribe((state: BreakpointState) => {
        if (state.breakpoints[Breakpoints.XSmall]) {
          this.hideImage = false;
        } else if (state.breakpoints[Breakpoints.Small]) {
          this.hideImage = false;
        } else if (state.breakpoints[Breakpoints.Medium]) {
          this.hideImage = false;
        } else if (state.breakpoints[Breakpoints.Large]) {
          this.hideImage = true;
        } else {
          this.hideImage = true;
        }
      });

    this.globalLanguageService.getGlobalLanguage().subscribe((value) => {
      this.getTermsAndConditions();
      this.getPrivacyPolicy();
    });

    this.sessionAnalyticsToken = window.sessionStorage.getItem(
      'sessionAnalyticsToken'
    );

    this.getPrivacyPolicy();
    this.getTermsAndConditions();
  }

  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['password'].value ===
      frm.controls['confirmPassword'].value
      ? null
      : { mismatch: true };
  }

  passwordEvent(field) {
    if (field === 'password') {
      this.passstatus = !this.passstatus;
    } else {
      this.confirmPasswordStatus = !this.confirmPasswordStatus;
    }
  }

  /****************************************************************************
     @Purpose     : clearValueForNgselect
     @Parameter   : Control
     @Return      : Na
  /****************************************************************************/
  resetSelection(control) {
    this.userDepartmentForm.controls[control].setValue(null);

    // if (control === 'region') {
    //   this.healthcareSubRegionArr = this.subregionsArr;
    // }
  }

  resetFormSubmit() {
    this.submitted = true;
    if (this.resetForm.valid) {
      let resetPasswordData = {
        token: this.signupToken,
        password: this.resetForm.value.password,
      };
      this.spinnerService.show();
      this.commonService
        .callApi(
          this.callAPIConstants.userSetPassword,
          resetPasswordData,
          'post',
          true,
          false
        )
        .then((success) => {
          this.spinnerService.hide();
          if (success.status === 1) {
            this.signInSubmit(success.data.oneTimeSiginToken);
          } else {
            this.showErrorService.popToast('error', success.message);
            this.resetForm.reset();
          }
        });
    }
  }

  registerFormSubmit() {
    this.submitted = true;
    if (this.signupForm.valid) {
      this.closeModalService.sendCloseEvent(true);
      let data = {
        emailId: this.signupForm.value.email,
        password: this.signupForm.value.password,
        termsAndCondition: this.signupForm.value.agreeTermsAndConditions,
        industry: this.selectedIndustry,
      };
      this.spinnerService.show();
      this.commonService
        .callApi(
          this.callAPIConstants.userSignupOnboarding,
          data,
          'post',
          true,
          false
        )
        .then((success) => {
          this.spinnerService.hide();
          if (success.status === 1) {
            success.showOtp
              ? this.showErrorService.popToastWithoutTimer(
                  'success',
                  success.message
                )
              : this.showErrorService.popToastWithoutTimer(
                  'success',
                  success.message
                );

            if (this.sessionAnalyticsToken != null) {
              this.updateQrCampaignVisits(this.sessionAnalyticsToken);
            }
          } else {
            if (success.isExists) {
              this.router.navigate([URLConstants.HOMEPAGE]);
            }
            this.showErrorService.popToastWithoutTimer('info', success.message);
            this.resetForm.reset();
          }
        });
    }
  }

  /****************************************************************************
          @Purpose     : send analytics
          @Parameter   : Na
          @Return      : Na
  /****************************************************************************/
  updateQrCampaignVisits(token) {
    this.commonService
      .callApi(
        this.callAPIConstants.updateQrVisits,
        { token: token, type: 'signup' },
        'post',
        true,
        false
      )
      .then((success) => {});
  }

  departmentAdd(value) {
    // this.userDepartmentForm.controls['otherDepartment'].setValue('');
    // this.subspecialtyArr.unshift({ name: 'Other Subspecialty' });
    if (value.name === 'Other Department') {
      this.otherDepartmentSelected = true;
      this.userDepartmentForm.controls['department'].setValue([
        'Other Department',
      ]);
    } else {
      const deptArray = this.userDepartmentForm.value.department;
      const arr = deptArray.filter((item) => item !== 'Other Department');
      this.userDepartmentForm.controls['department'].setValue(arr);
      this.otherDepartmentSelected = false;
    }
  }

  deptClear(value) {
    this.userDepartmentForm.controls['otherDepartment'].setValue('');
    this.userDepartmentForm.controls['subdepartment'].setValue(null);
    this.userDepartmentForm.controls['otherSubdepartment'].setValue('');
    if (value.label === 'Other Department') {
      this.otherDepartmentSelected = false;
    }
    this.otherSubdepartmentSelected = false;
  }

  /*************************************************************
    @Purpose     : SignIn
    @Parameter   : NA
    @Return      : NA
  /*************************************************************/
  signInSubmit(token) {
    let sigInData = {
      token: token,
    };
    this.commonService
      .callApi(
        this.callAPIConstants.OneTimeSigIn,
        sigInData,
        'post',
        true,
        false
      )
      .then((successSigInData) => {
        if (successSigInData.status === 1) {
          this.showGetSetup = true;
          this.showSetPassword = false;
          // this.modalRef.hide();
          this.localStorageService.setToken(
            'accessToken',
            successSigInData.access_token
          );
          this.localStorageService.setToken(
            'username',
            successSigInData.data.username
          );
          this.localStorageService.setToken(
            'currentUser',
            JSON.stringify(successSigInData.data)
          );
          this.localStorageService.setToken(
            'industry',
            successSigInData.data.industry
          );
          this.localStorageService.setToken(
            'filterToggle',
            JSON.stringify(false)
          );
          this.localStorageService.setToken(
            'scrollIndexSolution',
            JSON.stringify(0)
          );
          this.localStorageService.setToken(
            'dontShowOnboarding',
            JSON.stringify(true)
          );
          const preferredLanguage = successSigInData.data.preferredLanguage;
          this.globalLanguageService.sendGlobalLanguage(preferredLanguage);
          this.localStorageService.setToken('scrollIndex', JSON.stringify(0));
          this.router.navigate([URLConstants.FEED]);
        } else {
          this.showErrorService.popToast('error', successSigInData.message);
        }
      });
  }

  /*************************************************************
    @Purpose     : start setup
    @Parameter   : NA
    @Return      : NA
  /*************************************************************/
  startSetup() {
    // this.closeModalService.sendCloseEvent(true);
    // this.router.navigate([URLConstants.FEED]);

    // this.setUserDefaultData();
    this.getDomainDetails();
    this.getOnboardingData();
  }

  // closeModal() {
  //   this.closeModalService.sendCloseEvent(true);
  // }

  navigate(index) {
    if (index === 2) {
      this.departmentSubmitted = true;
      if (
        !_.isEmpty(this.userDepartmentForm.value.department) &&
        this.userDepartmentForm.value.department[0] === 'Other Department'
      ) {
        if (this.userDepartmentForm.value.otherDepartment === '') {
          this.userDepartmentForm
            .get('department')
            .setValidators([Validators.required]);
          this.userDepartmentForm.get('department').updateValueAndValidity();
          this.userDepartmentForm
            .get('department')
            .setErrors({ required: true });
        } else {
          this.userDepartmentForm.get('department').clearValidators();
          this.userDepartmentForm.get('department').updateValueAndValidity();
        }
      } else {
        if (_.isEmpty(this.userDepartmentForm.value.department)) {
          this.userDepartmentForm
            .get('department')
            .setValidators([Validators.required]);
          this.userDepartmentForm.get('department').updateValueAndValidity();
          this.userDepartmentForm
            .get('department')
            .setErrors({ required: true });
        }
      }
      if (!_.isEmpty(this.userDepartmentForm.value.subdepartment)) {
        this.userDepartmentForm.controls['subdepartment'].setValue(
          this.userDepartmentForm.value.subdepartment
        );
      } else {
        this.userDepartmentForm.controls['subdepartment'].setValue([]);
      }
      if (this.userDepartmentForm.valid) {
        let updateUserData = {
          department:
            !_.isEmpty(this.userDepartmentForm.value.department) &&
            !this.otherDepartmentSelected
              ? this.userDepartmentForm.value.department
              : [],
          subDepartment: !_.isEmpty(this.userDepartmentForm.value.subdepartment)
            ? this.userDepartmentForm.value.subdepartment
            : [],

          position: this.userDepartmentForm.value.role
            ? this.userDepartmentForm.value.role
            : '',
          otherPosition: this.userDepartmentForm.value.otherRole
            ? this.userDepartmentForm.value.otherRole
            : '',
        };
        this.editProfile(updateUserData, index);
      }
    }

    if (index === 3) {
      this.experienceSubmitted = true;
      if (this.userExperienceForm.valid) {
        let updateUserData = {
          yearsOfExperience: this.userExperienceForm.value.experience
            ? this.userExperienceForm.value.experience
            : '',
        };
        this.editProfile(updateUserData, index);
      }
    }

    if (index === 4) {
      this.value = this.value + 1;
      let updateUserData = {
        hospital: this.userHospitalForm.value.hospital
          ? this.userHospitalForm.value.hospital
          : '',
      };
      this.editProfile(updateUserData, index);
    }
  }

  navigateBack(index) {
    this.showingElement = index;
  }

  /****************************************************************************
       @Purpose     : getDomainDetails
       @Parameter   : $event
       @Return      : NA
  /****************************************************************************/
  getDomainDetails() {
    this.commonService
      .callApi(
        this.callAPIConstants.profileListingDetails,
        '',
        'get',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.userPersonalData = success.user;
          this.regionData = success.data;
          this.industry = success.user.industry;
          this.healthcareArr = this.regionData.regionList
            ? this.regionData.regionList
            : [];

          this.rolesArr = this.regionData.rolesList
            ? this.regionData.rolesList
            : [];

          this.rolesArr = this.rolesArr
            .filter((region) => region.country === 'Canada')
            .concat({ name: 'Other Position (Role)' })
            .filter(
              (value, index, self) =>
                index === self.findIndex((t) => t.name === value.name)
            );
          this.rolesArrTemp = this.rolesArr;
          this.subdepartmentArr = this.regionData.subdepartmentList
            ? this.regionData.subdepartmentList
            : [];
          this.subdepartmentArrTemp = this.regionData.subdepartmentList
            ? this.regionData.subdepartmentList
            : [];

          let Dept = this.deparmentArr.filter((dept) =>
            success.user.department.includes(dept.department)
          );
          const subspecialArr = Dept.map(
            (department) => department.subDepartment
          ).reduce(
            (accumulator, currentValue) => accumulator.concat(currentValue),
            []
          );
          this.subdepartmentArr = subspecialArr;
          this.userDepartmentForm.patchValue({
            role: this.userPersonalData.position
              ? this.userPersonalData.position
              : null,

            otherDepartment: this.userPersonalData.otherDepartment
              ? this.userPersonalData.otherDepartment
              : '',
            otherRole: this.userPersonalData.otherPosition
              ? this.userPersonalData.otherPosition
              : '',
            department: !_.isEmpty(this.userPersonalData.department)
              ? this.userPersonalData.department
              : null,
            subdepartment: !_.isEmpty(this.userPersonalData.subDepartment)
              ? this.userPersonalData.subDepartment
              : null,
          });
          if (this.userPersonalData.otherPosition) {
            this.userDepartmentForm.controls['role'].setValue(
              'Other Position (Role)'
            );
          }

          this.setUserDefaultData();
        } else {
          // this.showErrorService.popToast('error', success.message);
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  /****************************************************************************
     @Purpose     : SetValueForNgselect
     @Parameter   : Control,value
     @Return      : Na
  /****************************************************************************/
  setValueForSelect(control, value) {
    this.userDepartmentForm.controls[control].setValue(value.name);

    if (control === 'role') {
      this.userDepartmentForm.controls['otherRole'].setValue('');

      // this.otherDepartmentSelected = false;
      // this.otherSubdepartmentSelected = false;
    }

    if (control === 'department') {
      // this.userDepartmentForm.controls['otherDepartment'].setValue('');
      const deptName = value.map((value) => value.name);
      const matchingDepartments = this.deparmentArrTemp.filter((department) =>
        deptName.includes(department.name)
      );
      const subspecialArr = matchingDepartments
        .map((department) => department.subDepartment)
        .reduce(
          (accumulator, currentValue) => accumulator.concat(currentValue),
          []
        );
      this.subdepartmentArr = subspecialArr;
    }
  }

  industrySelection(industry) {
    this.showIndustrySelection = false;
    this.selectedIndustry = industry;
    this.localStorageService.setToken('industry', industry);
  }

  /****************************************************************************
   @Purpose     : GetUserOnboardingData
   @Parameter   : Na
   @Return      : NA
   /****************************************************************************/
  getOnboardingData() {
    this.commonService
      .callApi(
        this.callAPIConstants.userGetOnboardingData,
        '',
        'get',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.onboardingSteps = success.data.onBoardingSteps;
          this.userIndustry = success.data.industry;
          success.data.onBoardingSteps.map((element) => {
            if (element.completed) {
              this.value = this.value + 1;
            }
          });

          this.showingElement = _.find(
            this.onboardingSteps,
            function (elemennt) {
              return !elemennt.completed;
            }
          ).step;
          this.showInitialModal = false;
        } else {
          this.showErrorService.popToast('error', success.message);
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  /****************************************************************************
     @Purpose     : SetValueForNgselect
     @Parameter   : Control,value
     @Return      : Na
  /****************************************************************************/
  setValueForSelectAviation(control, value) {
    this.userRegionsForm.get('region').setValue(null);
    this.userPlaceOfServiceForm.get('placeOfService').setValue(null);
    this.userAirlineForm.get('airline').setValue(null);

    this.userPlaceOfServiceForm.reset();
    this.userRegionsForm.reset();
    this.userPlaceOfServiceForm.reset();

    this.placeOfServiceSubmitted = false;
    this.regionsSubmitted = false;
    this.airlineSubmitted = false;

    if (control === 'country') {
      this.placeOfServiceArr = this.tempPlaceOfServiceArr;
      this.airlinesArr = this.tempAirlinesArr;
      this.aviationRegionsArr = this.tempAviationRegionsArr;
      let aviationRegions = this.aviationRegionsArr.filter(
        (region) => region.country === value.name
      );
      this.aviationRegionsArr = aviationRegions;

      let services = this.placeOfServiceArr.filter(
        (service) => service.country === value.name
      );
      this.placeOfServiceArr = services;

      let airlines = this.airlinesArr.filter(
        (airline) => airline.country === value.name
      );
      this.airlinesArr = airlines;
    }
  }

  navigateAviation(index, type) {
    if (index === 2) {
      this.countrySubmitted = true;
      if (this.userCountryForm.valid) {
        let updateUserData = {
          country: this.userCountryForm.value.country
            ? this.userCountryForm.value.country
            : '',
          // region: '',
          // aviationPlaceOfService: '',
          // airline: '',
        };
        this.editProfile(updateUserData, index);

        this.userRegionsForm.get('region').setValue(null);
        this.userPlaceOfServiceForm.get('placeOfService').setValue(null);
        this.userAirlineForm.get('airline').setValue(null);

        this.userPlaceOfServiceForm.reset();
        this.userRegionsForm.reset();
        this.userPlaceOfServiceForm.reset();

        this.placeOfServiceSubmitted = false;
        this.regionsSubmitted = false;
        this.airlineSubmitted = false;
      }
    }

    if (index === 3) {
      this.typeSubmitted = true;
      if (this.userTypeForm.valid) {
        let updateUserData = {
          aviationType: this.userTypeForm.value.type,
        };
        this.editProfile(updateUserData, index);
      }
    }

    if (index === 4) {
      if (type === 'service') {
        this.placeOfServiceSubmitted = true;
        if (this.userPlaceOfServiceForm.valid) {
          let updateUserData = {
            aviationPlaceOfService: this.userPlaceOfServiceForm.value
              .placeOfService
              ? this.userPlaceOfServiceForm.value.placeOfService
              : '',
          };
          this.editProfile(updateUserData, index);
        }
      }

      if (type === 'region') {
        this.regionsSubmitted = true;
        if (this.userRegionFormAviation.valid) {
          let updateUserData = {
            region: this.userRegionFormAviation.value.region
              ? this.userRegionFormAviation.value.region
              : '',
          };
          this.editProfile(updateUserData, index);
        }
      }

      if (type === 'airline') {
        this.airlineSubmitted = true;
        if (this.userAirlineForm.valid) {
          let updateUserData = {
            airline: this.userAirlineForm.value.airline
              ? this.userAirlineForm.value.airline
              : '',
          };
          this.editProfile(updateUserData, index);
        }
      }
    }

    if (index === 5) {
      this.expertiseSubmitted = true;
      if (this.userExpertiseForm.valid) {
        let updateUserData = {
          expertise: this.userExpertiseForm.value.expertise
            ? this.userExpertiseForm.value.expertise
            : '',
        };
        this.editProfile(updateUserData, index);
      }
    }

    if (index === 6) {
      this.experienceSubmitted = true;
      if (this.userExperienceForm.valid) {
        let updateUserData = {
          yearsOfExperience: this.userExperienceForm.value.experience
            ? this.userExperienceForm.value.experience
            : '',
        };
        this.editProfile(updateUserData, index);
      }
    }
  }

  /****************************************************************************
   @Purpose     : editUserOnboardingData
   @Parameter   : Na
   @Return      : NA
   /****************************************************************************/
  editOnboardingData() {
    this.commonService
      .callApi(
        this.callAPIConstants.userEditOnboardingData,
        {
          onboardingSteps: this.onboardingSteps,
          completed: this.onboardingCompleted,
        },
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          if (
            !success.data.onboardingCompleted &&
            !success.data.dontShowOnboarding
          ) {
            this.value = 0;
            this.onboardingSteps = success.data.onBoardingSteps;
            success.data.onBoardingSteps.map((element) => {
              if (element.completed) {
                this.value = this.value + 1;
              }
            });

            // this.showingElement = _.find(
            //   this.onboardingSteps,
            //   function (elemennt) {
            //     return !elemennt.completed;
            //   }
            // ).step;
          }
        } else {
          this.showErrorService.popToast('error', success.message);
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  /****************************************************************************
     @Purpose     : editProfile
     @Parameter   : $event
     @Return      : NA
  /****************************************************************************/
  editProfile(updateUserData, index) {
    this.commonService
      .callApi(
        this.callAPIConstants.updateUserPersonalData,
        updateUserData,
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          // this.showErrorService.popToast('success', success.message);
          this.onboardingSteps[index - 2]['completed'] = true;
          if (index === 4) {
            this.onboardingCompleted = true;
          }
          this.editOnboardingData();
          this.showingElement = index;
          this.localStorageService.setToken(
            'currentUser',
            JSON.stringify(success.data)
          );
          this.localStorageService.setToken('industry', success.data.industry);
          this.setUserDefaultData();
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }

  /****************************************************************************
     @Purpose     : set profile data to onboarding
     @Parameter   : $event
     @Return      : NA
  /****************************************************************************/
  async setUserDefaultData() {
    let user = this.localStorageService.getToken('currentUser');
    let data = await JSON.parse(user);
    let regionName = data.region;
    let subRegionName = data.subRegion;
    let departmentName = data.department;
    let country = data.country || 'Canada';
    let experience = data.yearsOfExperience;
    let hospital = data.hospital;
    let placeOfService = data.aviationPlaceOfService;
    let aviationType = data.aviationType;
    let airline = data.airline;
    let expertise = data.expertise;
    // let designation = data.designation;
    let healthcareRole = data.healthcareRole;
    let position = data.position;
    let otherDesignation = data.otherDesignation;
    let otherRole = data.otherHealthcareRole;
    let otherPosition = data.otherPosition;
    let otherDepartment = data.otherDepartment;
    // let subspecialties = data.subspecialties;
    // let otherSubspecialties = data.otherSubspecialties;

    // this.selectedDesignation = designation;

    departmentName !== '' &&
      this.userDepartmentForm.get('department').setValue(departmentName);
    experience !== '' &&
      this.userExperienceForm.get('experience').setValue(experience);
    hospital !== '' && this.userHospitalForm.get('hospital').setValue(hospital);

    position !== ''
      ? this.userDepartmentForm.get('role').setValue(position)
      : this.userDepartmentForm.get('role').setValue(null);

    otherPosition
      ? this.userDepartmentForm.get('otherRole').setValue(otherPosition)
      : this.userDepartmentForm.get('otherRole').setValue(null);
    otherDepartment !== ''
      ? this.userDepartmentForm.get('otherDepartment').setValue(otherDepartment)
      : this.userDepartmentForm.get('otherDepartment').setValue(null);

    if (otherDepartment === '' || otherDepartment == null) {
      this.otherDepartmentSelected = false;
    }

    if (country !== '') {
      // this.regionsArr = regions;

      this.rolesArr = this.rolesArr
        .filter((region) => region.country === 'Canada')
        .concat({ name: 'Other Position (Role)' })
        .filter(
          (value, index, self) =>
            index === self.findIndex((t) => t.name === value.name)
        );
    }

    if (otherRole) {
      this.userDepartmentForm.controls['role'].setValue(
        'Other Position (Role)'
      );
    }
    if (otherDepartment) {
      this.userDepartmentForm.controls['department'].setValue([
        'Other Department',
      ]);
      this.otherDepartmentSelected = true;
    }

    if (this.userIndustry === 'healthcare') {
      if (country === null || regionName === null || subRegionName === null) {
        this.setUserDefaultDataUsingDomain();
      }
    }
  }

  /****************************************************************************
     @Purpose     : set profile data to onboarding
     @Parameter   : $event
     @Return      : NA
  /****************************************************************************/
  async setUserDefaultDataUsingDomain() {
    let user = this.localStorageService.getToken('currentUser');
    let data = await JSON.parse(user);
    let domainEmail = data.emailId;

    let signUpDomain = _.last(domainEmail.split('@')).toLowerCase().trim();

    this.commonService
      .callApi(
        this.callAPIConstants.userGetDomainData,
        {
          domainName: signUpDomain,
        },
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          let country = success.data.region.country;
          let regionName = success.data.region.name;
          let subRegionName = success.data.subRegion.name;

          country !== '' &&
            this.userCountryForm.get('country').setValue(country);
          regionName !== '' &&
            this.userRegionsForm.get('region').setValue(regionName);
          subRegionName !== '' &&
            this.userRegionsForm.get('subRegion').setValue(subRegionName);

          if (country !== '') {
            this.selectedCountry = country;
            this.regionsArr = this.tempRegionsArr;
            let regions = this.regionsArr.filter(
              (region) => region.country === country
            );
            this.regionsArr = regions;

            this.rolesArr = this.rolesArr
              .filter((region) => region.country === 'Canada')
              .concat({ name: 'Other Position (Role)' })
              .filter(
                (value, index, self) =>
                  index === self.findIndex((t) => t.name === value.name)
              );
          }
        } else {
          // this.showErrorService.popToast('error', success.message);
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  /****************************************************************************
     @Purpose     : set profile data to onboarding
     @Parameter   : $event
     @Return      : NA
  /****************************************************************************/

  closeModal(flag: boolean) {
    this.localStorageService.setToken(
      'dontShowOnboarding',
      JSON.stringify(true)
    );
    this.closeModalService.sendCloseEvent(flag);
  }
  onLanguageSelection(event) {
    const selectedValue = event.value;

    const data = {
      preferredLanguage: selectedValue,
    };

    this.spinnerService.show();

    this.commonService
      .callApi(
        this.callAPIConstants.updateUserPersonalData,
        data,
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          this.globalLanguageService.sendGlobalLanguage(selectedValue);
          this.spinnerService.hide();
          this.showErrorService.popToast('success', success.message);
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      })
      .catch((error) => {
        this.spinnerService.hide();
        console.error(error);
        this.showErrorService.popToast(
          'error',
          'Failed to update language preference.'
        );
      });
  }

  getPrivacyPolicy() {
    let currentLanguage = this.localStorageService.getToken('language');
    let data = {
      pageId: 'privacy_policy',
      ip: this.ip,
      language: currentLanguage,
    };
    this.commonService
      .callApi(this.callAPIConstants.cmsPages, data, 'post', true)
      .then((success) => {
        if (success.status === 1) {
          this.privacyPlicy['content'] = success.data.description;
        }
      });
  }

  getTermsAndConditions() {
    let currentLanguage = this.localStorageService.getToken('language');
    let data = {
      pageId: 'terms_and_condition',
      ip: this.ip,
      language: currentLanguage,
    };
    this.commonService
      .callApi(this.callAPIConstants.cmsPages, data, 'post', true)
      .then((success) => {
        if (success.status === 1) {
          this.termsAndConditions = success.data.description;
        }
      });
  }

  openTermsAndConditions(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'onboarding-modal modal-dialog-centered',
      ignoreBackdropClick: false,
      keyboard: false,
    });
  }
  openPrivacyPolicy(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, {
      class: 'onboarding-modal modal-dialog-centered',
      ignoreBackdropClick: false,
      keyboard: false,
    });
  }
  deptSelect(value) {
    this.userDepartmentForm.controls['otherDepartment'].setValue('');
    const subspecialArr = value
      .map((department) => department.subDepartment)
      .reduce(
        (accumulator, currentValue) => accumulator.concat(currentValue),
        []
      );
    this.subdepartmentArr = subspecialArr;
  }
  subClear(value) {
    this.userDepartmentForm.controls['otherSubdepartment'].setValue('');
    if (value.label === 'Other Subdepartment') {
      this.otherSubdepartmentSelected = false;
    }
  }

  subdepartmentAdd(value) {
    const subdepartmentArray = this.userDepartmentForm.value.subdepartment;
    const filteredArray = subdepartmentArray.filter(
      (item) => item !== 'Other Subdepartment'
    );
    this.userDepartmentForm.controls['subdepartment'].setValue(filteredArray);
    // this.otherSubdepartmentSelected = false;
  }
}
