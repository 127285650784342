import {
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
  DoCheck,
  ElementRef,
  Renderer2,
} from '@angular/core';
import {
  Router,
  ActivatedRoute,
  Event,
  NavigationStart,
} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
// Bootrsap Module
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

// Constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
// Service
import { LocalStorageService } from '../../../common/services/service/local-storage.service';
import { LogoutService } from '../../../common/services/service/logout.service';
// Constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';

// Service
import { CommonService } from '../../../common/services/service/common.service';
import { ShowErrorService } from '../../../common/services/service/show-error.service';
import { RestrictKeyPressService } from '../../../common/services/service/restrict-key-press.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, concat, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { GlobalSearchService } from 'src/app/common/services/service/global-search';
import { GlobalFilterService } from 'src/app/common/services/service/global-filter.service';
import { GlobalLanguageService } from 'src/app/common/services/service/global-language.service';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  merge,
  switchMap,
} from 'rxjs/operators';

import {
  BreakpointObserver,
  BreakpointState,
  Breakpoints,
} from '@angular/cdk/layout';
import { ScrollService } from 'src/app/common/services/service/scroll.service';
import { SignuponboardingComponent } from 'src/app/modules/signuponboarding/signuponboarding.component';
import { SignuponboardinginstitutionComponent } from 'src/app/modules/signuponboardinginstitution/signuponboardinginstitution.component';
import { CloseModalService } from 'src/app/common/services/service/close-modal.service';
import * as countries from '../../../common/constants/json/countries.json';
import { TranslateService } from '@ngx-translate/core';
import { TabSelectionService } from 'src/app/common/services/service/tab-selection.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [],
})
export class HeaderComponent implements OnInit, DoCheck {
  @ViewChild('mysearch') mysearch: ElementRef;
  reqData: any;
  bindValue: string = 'searchText';
  public internalAssigneList: Observable<any>;
  notFoundText1: any;
  public internalAssigneLoader: boolean = false;
  public internalAssigneDataSource = new Subject<any>();
  public focus$ = new Subject<string>();
  //Variable Declaration
  public callAPIConstants = callAPIConstants;
  public URLConstants = URLConstants;
  public modalRef: BsModalRef | null;
  public modalRefOnboardingSignup: BsModalRef;
  public registerForm: FormGroup;
  public OTPForm: FormGroup;
  public signInForm: FormGroup;
  public forgetEmailForm: FormGroup;
  public recommendDomain: FormGroup;
  public searchForm: FormGroup;
  public filterForm: FormGroup;
  public filterFormAviation: FormGroup;
  public isRegsubmitted: boolean = false;
  public isSignInsubmitted: boolean = false;
  public isFilterSubmitted: boolean = false;
  public isRecommantedsubmitted: boolean = false;
  public submitted: boolean = false;
  public selectedCar: number;
  public userEmail: string;
  public modalRef2: BsModalRef;
  public modalRef3: BsModalRef;
  public modalRef4: BsModalRef;
  public modalRef5: BsModalRef;
  public modalRef6: BsModalRef;
  public modalRef7: BsModalRef;
  public modalRef8: BsModalRef;
  public status: boolean = false;
  @ViewChild('verified') verified: TemplateRef<any>;
  @ViewChild('msg') msg: TemplateRef<any>;
  public isResendOTP: boolean = false;
  public isTimeOut: boolean = false;
  public isAuthUser: boolean = false;
  public userData: any;
  public config = {
    allowNumbersOnly: true,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: true,
  };
  public signUpPasswordStatus: boolean = false;
  public signUpChangePasswordStatus: boolean = false;
  public passstatus: boolean = false;
  public defaultImage = 'assets/images/defoult-edite.svg';
  public countdownTimer: any;
  public initialValues: any;
  public searchItems;
  public searchText: any;
  public searchTextList: any = [];
  public searchArr: any = [];
  public notificationCount: any;
  public noResult: string = 'No recent search available';
  public hideCreateIncidentBtn = false;
  public hideFilterButton = false;
  public deparmentList: Array<any> = [];
  public regionList: Array<any> = [];
  public aviationRegionList: Array<any> = [];
  private temporaryAviationRegionsStore: Array<any> = [];
  public subRegionList: Array<any> = [];
  public tagListArray: Array<any> = [];
  public medicationErrors: Array<any> = [];
  public filterData: any = {};
  public countries: any;
  private temporaryRegionsStore: Array<any> = [];
  private temporaryPlaceOfServiceStore: Array<any> = [];
  private temporarySubRegionsStore: Array<any> = [];
  private temporaryAirlineStore: Array<any> = [];
  public attemptsLeft = null;
  public backToTopButton: boolean = false;
  public showRedirectModal: boolean = false;
  public globalSignup: Boolean = false;
  public typeSelected: Boolean = true;
  public privateOrCommercialSelected: Boolean = false;
  public airlineSelected: Boolean = false;
  public militarySelected: Boolean = false;
  public closeModalSubscription: any;
  public changeHeaderGradient: boolean = false;
  public contactForm: FormGroup;
  public contactSubmitted: boolean = false;
  public countryList: Array<any> = [];
  public typesArr: Array<any> = [
    'Military',
    'Private',
    'Commercial',
    'Airline',
  ];
  public airlineList: Array<any> = [];
  public placeOfServiceList: Array<any> = [];
  public expertiseList: Array<any> = [];
  public industryArr = [
    { name: 'Aviation', value: 'aviation' },
    { name: 'Healthcare', value: 'healthcare' },
  ];
  public industry = null;
  siteLanguage = 'English';
  languageList = [
    { code: 'en', label: 'English' },
    { code: 'de', label: 'Deutsch' },
    { code: 'fr', label: 'French' },
  ];

  selected = 'en';
  receivedLanguage: any = 'en';
  public germanTagListArrayTemp: Array<any> = [];
  public englishTagListArrayTemp: Array<any> = [];
  public frenchTagListArrayTemp: Array<any> = [];
  isSubmitting = false;

  airportListArray: Observable<any[]> = of([]);
  searchTextAirport: string = '';
  public typeAheadSource = new Subject<string>();
  public otherAirportSelected: boolean = false;
  public typeAheadSourceSearch = new Subject<string>();
  public logedUserData: any = {};
  public institution: string = '';
  constructor(
    public router: Router,
    public localStorageService: LocalStorageService,
    public logoutService: LogoutService,
    public modalService: BsModalService,
    public formBuilder: FormBuilder,
    public showErrorService: ShowErrorService,
    public restrictKeyPressService: RestrictKeyPressService,
    private commonService: CommonService,
    public spinnerService: NgxSpinnerService,
    public http: HttpClient,
    public GlobalSearch: GlobalSearchService,
    public breakpointObserver: BreakpointObserver,
    public GlobalFilterService: GlobalFilterService,
    public route: ActivatedRoute,
    public scrollService: ScrollService,
    public activatedRoute: ActivatedRoute,
    public closeModalService: CloseModalService,
    private renderer: Renderer2,
    private location: Location,
    public translate: TranslateService,
    public globalLanguageService: GlobalLanguageService,
    private tabSelectionService: TabSelectionService
  ) {
    let language = localStorageService.getToken('language');
    if (language) {
      this.selected = language;
      this.translate.use(this.selected);
    }
    this.logedUserData = JSON.parse(
      this.localStorageService.getToken('currentUser')
    );
    // if (language == 'de') {
    //   this.industryArr[0].name = 'Luftfahrt';
    //   this.industryArr[1].name = 'Gesundheitswesen ';
    // } else if (language == 'fr') {
    //   this.industryArr[0].name = 'Aviation';
    //   this.industryArr[1].name = 'Soins de santé';
    // } else {
    //   this.industryArr[0].name = 'Aviation';
    //   this.industryArr[1].name = 'Healthcare';
    // }
    translate.addLangs(['en', 'de', 'fr']);
    if (router.url === '/feed') {
      this.backToTopButton = true;
    }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (event.url === '/feed') {
          this.backToTopButton = true;
        } else {
          this.backToTopButton = false;
        }
        if (event.url === '/about') {
          this.changeHeaderGradient = true;
        } else {
          this.changeHeaderGradient = false;
        }
      }
    });
  }
  ngAfterViewInit() {}

  onChange() {
    this.localStorageService.setToken('language', this.receivedLanguage);
    this.translate.use(this.receivedLanguage);

    // if (this.receivedLanguage == 'de') {
    //   this.industryArr[0].name = 'Luftfahrt';
    //   this.industryArr[1].name = 'Gesundheitswesen ';
    // } else if (this.receivedLanguage == 'fr') {
    //   this.industryArr[0].name = 'Aviation';
    //   this.industryArr[1].name = 'Soins de santé';
    // } else {
    //   this.industryArr[0].name = 'Aviation';
    //   this.industryArr[1].name = 'Healthcare';
    // }
  }

  // onLanguageChange(language) {
  //   this.localStorageService.setToken('language', language);
  //   this.translate.use(language);
  //   this.globalLanguageService.sendGlobalLanguage(language);
  // }

  onLanguageSelection(language) {
    const selectedValue = language;

    const data = {
      preferredLanguage: selectedValue,
    };

    if (!this.isAuthUser) {
      this.globalLanguageService.sendGlobalLanguage(language);
      return;
    }
    this.commonService
      .callApi(
        this.callAPIConstants.updateUserPersonalData,
        data,
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
          // console.log(`language changed`, selectedValue);
          // this.localStorage.setToken('language', selectedValue);
          // this.translate.use(selectedValue);
          this.globalLanguageService.sendGlobalLanguage(selectedValue);
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      })
      .catch((error) => {
        this.spinnerService.hide();
        console.error(error);
        this.showErrorService.popToast(
          'error',
          'Failed to update language preference.'
        );
      });
  }

  ngOnInit() {
    // if (this.industry === 'aviation') {
    //   console.log('first');
    this.loadTypeheadSearch();
    // }
    this.globalLanguageService.getGlobalLanguage().subscribe((value) => {
      this.receivedLanguage = value.data;
      this.onChange();
      if (this.receivedLanguage == 'en') {
        this.tagListArray = this.englishTagListArrayTemp;
      } else if (this.receivedLanguage == 'de') {
        this.tagListArray = this.germanTagListArrayTemp;
      } else if (this.receivedLanguage == 'fr') {
        this.tagListArray = this.frenchTagListArrayTemp;
      }
    });
    this.countryList = countries.default;
    this.countryList.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    this.myFormValues();
    this.activatedRoute.queryParams.subscribe((params) => {
      this.globalSignup = params['globalSignup'] === 'true' ? true : false;
      this.institution = params['institution'] || '';
      if (this.router.url.split('?')[0] !== '/home') {
        if (this.globalSignup) {
          this.openSignupModal();
          this.closeModalSubscription = this.closeModalService
            .getCloseModalService()
            .subscribe((res) => {
              this.modalRefOnboardingSignup.hide();
            });
        }
      }
    });

    this.registerForm = this.formBuilder.group(
      {
        fullName: ['', [Validators.required]],
        email: [
          '',
          [
            Validators.required,
            Validators.pattern(
              '^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$'
            ),
          ],
        ],
        password: [
          '',
          [
            Validators.required,
            Validators.pattern(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~-]).{8,}$/
            ),
          ],
        ],
        confirmPassword: ['', [Validators.required]],
        agreeTermsAndConditions: [false, [Validators.requiredTrue]],
      },
      { validator: this.passwordMatchValidator }
    );

    this.OTPForm = this.formBuilder.group({
      userOTP: ['', [Validators.required]],
    });

    this.signInForm = this.formBuilder.group({
      emailOrLoginId: ['', [Validators.required]],
      password: ['', [Validators.required]],
      captcha: ['', this.attemptsLeft !== null && [Validators.required]],
    });

    this.forgetEmailForm = this.formBuilder.group({
      forgetEmail: ['', [Validators.required]],
    });

    this.recommendDomain = this.formBuilder.group({
      userRecommendDomain: ['', [Validators.required]],
      domainName: [''],
    });
    this.searchForm = this.formBuilder.group({
      searchText: [''],
    });

    this.filterForm = this.formBuilder.group({
      hospitalName: [''],
      country: [null],
      region: [null],
      subRegion: [null],
      department: [null],
      procedures: [[]],
      medicationeError: [null],
    });

    this.filterFormAviation = this.formBuilder.group({
      airstrip: [null],
      country: [null],
      region: [null],
      type: [null],
      expertise: [null],
      procedures: [[]],
      airline: [null],
      otherAirstrip: [null],
      // placeOfService: [null],
    });

    this.breakpointObserver
      .observe([
        Breakpoints.Large,
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.XLarge,
      ])
      .subscribe((state: BreakpointState) => {
        if (
          state.breakpoints[Breakpoints.Large] ||
          state.breakpoints[Breakpoints.XLarge]
        ) {
          this.hideCreateIncidentBtn = false;
          this.hideFilterButton = false;
        } else if (state.breakpoints[Breakpoints.XSmall]) {
          this.hideFilterButton = true;
          this.hideCreateIncidentBtn = true;
          this.showRedirectModal = true;
        } else if (state.breakpoints[Breakpoints.Small]) {
          // this.hideFilterButton = true;
          this.hideCreateIncidentBtn = true;
          this.showRedirectModal = true;
        } else {
          this.hideCreateIncidentBtn = true;
          this.hideFilterButton = false;
        }
      });
  }

  changeSiteLanguage(localeCode: string): void {
    const selectedLanguage = this.languageList
      .find((language) => language.code === localeCode)
      ?.label.toString();
    if (selectedLanguage) {
      this.siteLanguage = selectedLanguage;
      this.translate.use(localeCode);
    }
    const currentLanguage = this.translate.currentLang;
    // console.log('currentLanguage', currentLanguage);
  }

  /*************************************************************
      @Purpose     : openModal
      @Parameter   : template: TemplateRef<any>
      @Return      : NA
    /*************************************************************/
  openSignupModal() {
    const componentToShow =
      this.institution === 'Cheo'
        ? SignuponboardinginstitutionComponent
        : SignuponboardingComponent;

    this.modalRefOnboardingSignup = this.modalService.show(componentToShow, {
      class: 'onboarding-modal modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  ngDoCheck() {
    if (this.localStorageService.getToken('accessToken')) {
      this.isAuthUser = true;
      this.userData = JSON.parse(
        this.localStorageService.getToken('currentUser')
      );
      this.setIndustry();
      // this.industry = this.userData.industry;
    } else {
      this.isAuthUser = false;
    }
  }

  myFormValues() {
    this.contactForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      emailId: [
        '',
        [
          Validators.required,
          Validators.pattern(
            '^([_a-z0-9]+(.[_a-z0-9]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,5}))|(d+$)$'
          ),
        ],
      ],
      message: ['', [Validators.required]],
    });
  }

  /****************************************************************************
         @Purpose     : submitReachForm
         @Parameter   : Na
         @Return      : Na
 /****************************************************************************/
  submitReachForm() {
    this.contactSubmitted = true;
    if (this.contactForm.valid) {
      const data = this.contactForm.value;
      this.commonService
        .callApi(this.callAPIConstants.reachUS, data, 'post', true, false)
        .then((success) => {
          if (success.status === 1) {
            this.modalRef.hide();
            this.showErrorService.popToast('success', success.message);
            this.contactSubmitted = false;
            this.contactForm.reset();
            this.contactForm.reset(this.contactForm.value);
          } else {
            this.showErrorService.popToast('success', success.message);
          }
        });
    }
  }

  setIndustry() {
    this.industry = this.userData.industry;
  }

  /*************************************************************
				  @Purpose     : passwordMatchValidator
				  @Parameter   : frm
				  @Return      : NA
		/*************************************************************/
  passwordMatchValidator(frm: FormGroup) {
    return frm.controls['password'].value ===
      frm.controls['confirmPassword'].value
      ? null
      : { mismatch: true };
  }

  clickEvent() {
    this.status = !this.status;
  }

  changeParams(value: Boolean) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        globalSignup: value,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true,
    });
  }

  /*************************************************************
			  @Purpose     : openModals
			  @Parameter   : template
			  @Return      : NA
	/*************************************************************/
  openModal(template: TemplateRef<any>) {
    this.signInForm.reset();
    this.modalRef = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.changeParams(false);
  }

  openModal2(template: TemplateRef<any>) {
    this.changeParams(true);
    // this.registerForm.reset();
    // this.modalRef2 = this.modalService.show(template, {
    //   class: 'modal-dialog modal-dialog-centered',
    //   ignoreBackdropClick: true,
    //   keyboard: false,
    // });
  }
  openModal3(template: TemplateRef<any>) {
    this.forgetEmailForm.reset();
    this.modalRef3 = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }
  openModal4(template: TemplateRef<any>) {
    this.recommendDomain.reset();
    this.modalRef4 = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  openModal5(template: TemplateRef<any>) {
    this.modalRef5 = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  openModal6(template: TemplateRef<any>) {
    this.modalRef6 = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false,
    });
  }

  openModal17(template: TemplateRef<any>) {
    this.setIndustry();
    this.getIncidentData();
    this.loadTypehead();
    this.modalRef7 = this.modalService.show(template, {
      class: 'filter-modal modal-dialog filter-modal-position',
      ignoreBackdropClick: true,
      backdrop: false,
      keyboard: false,
    });
  }

  openModal8(template: TemplateRef<any>) {
    this.modalRef8 = this.modalService.show(template, {
      class: 'modal-dialog modal-dialog-centered',
      ignoreBackdropClick: false,
      keyboard: false,
    });
  }

  passwordEvent() {
    this.passstatus = !this.passstatus;
  }

  /****************************************************************************
     @Purpose     : SetValueForNgselect
     @Parameter   : Control,value
     @Return      : Na
  /****************************************************************************/
  setValueForSelect(control, value) {
    // this.filterForm.controls[control].setValue(value.name);
    // this.filterFormAviation.controls[control].setValue(value.name);

    if (control === 'type') {
      this.typeSelected = false;
      if (value === 'Private' || value === 'Commercial') {
        this.privateOrCommercialSelected = true;
        this.airlineSelected = false;
        this.filterFormAviation.patchValue({
          airline: null,
        });
      }
      if (value === 'Airline') {
        this.privateOrCommercialSelected = false;
        this.airlineSelected = true;
        this.filterFormAviation.patchValue({
          region: null,
        });
      }
      if (value === 'Military') {
        this.privateOrCommercialSelected = false;
        this.airlineSelected = false;
        this.filterFormAviation.patchValue({
          region: null,
        });

        this.filterFormAviation.patchValue({
          airline: null,
        });
      }
    }
    if (control === 'airstrip') {
      // this.privateOrCommercialSelected = false;
      // this.airlineSelected = true;
      // this.filterFormAviation.patchValue({
      //   region: null,
      // });
    }

    if (control === 'country') {
      this.regionList = this.temporaryRegionsStore;
      let regions = this.regionList.filter(
        (region) => region.country === value.name
      );
      this.regionList = regions;

      this.aviationRegionList = this.temporaryAviationRegionsStore;
      let aviationRegions = this.aviationRegionList.filter(
        (region) => region.country === value.name
      );
      this.aviationRegionList = aviationRegions;

      // this.placeOfServiceList = this.temporaryPlaceOfServiceStore;
      // let placeOfServices = this.placeOfServiceList.filter(
      //   (item) => item.country === value.name
      // );
      // this.placeOfServiceList = placeOfServices;

      this.airlineList = this.temporaryAirlineStore;
      let airlines = this.airlineList.filter(
        (item) => item.country === value.name
      );
      this.airlineList = airlines;
    }

    if (control === 'region') {
      this.subRegionList = this.temporarySubRegionsStore;
      let subRegion = value.subRegionList;
      this.subRegionList = subRegion;
    }
  }

  /****************************************************************************
     @Purpose     : clearValueForNgselect
     @Parameter   : Control
     @Return      : Na
  /****************************************************************************/
  resetSelection(control) {
    // this.filterForm.controls[control].setValue(null);

    if (control === 'country') {
      this.regionList = this.temporaryRegionsStore;
      this.airlineList = this.temporaryAirlineStore;
      this.aviationRegionList = this.temporaryAviationRegionsStore;
      // this.placeOfServiceList = this.temporaryPlaceOfServiceStore;
    }

    if (control === 'region') {
      this.subRegionList = this.temporarySubRegionsStore;
    }

    if (control === 'type') {
      this.typeSelected = true;
    }
  }

  /*************************************************************
			  @Purpose     : signUpPasswordEvent
			  @Parameter   : field
			  @Return      : NA
	/*************************************************************/
  signUpPasswordEvent(field) {
    if (field === 'password') {
      this.signUpPasswordStatus = !this.signUpPasswordStatus;
    } else {
      this.signUpChangePasswordStatus = !this.signUpChangePasswordStatus;
    }
  }

  get f() {
    return this.registerForm.controls;
  }

  /****************************************************************************
         @Purpose     : GetForm controls
         @Parameter   : Na
         @Return      : Na
 /****************************************************************************/
  get cf() {
    return this.contactForm.controls;
  }

  /*************************************************************
		  @Purpose     : ForgetEmail
		  @Parameter   : NA
		  @Return      : NA
	/*************************************************************/
  OnForgetEmailSubmit() {
    this.submitted = true;
    if (this.forgetEmailForm.valid) {
      let forgetEmailData = {
        emailId: this.forgetEmailForm.value.forgetEmail,
      };
      this.spinnerService.show();
      this.commonService
        .callApi(
          this.callAPIConstants.ForgetEmail,
          forgetEmailData,
          'post',
          true,
          false
        )
        .then((successForgetPasswordData) => {
          this.spinnerService.hide();
          if (successForgetPasswordData.status === 1) {
            this.modalRef3.hide();
            this.showErrorService.popToast(
              'success',
              successForgetPasswordData.message
            );
          } else {
            this.showErrorService.popToast(
              'error',
              successForgetPasswordData.message
            );
          }
        });
    }
  }
  /*************************************************************
	  @Purpose     : onOtpChange
	  @Parameter   : event
	  @Return      : NA
	/*************************************************************/
  onOtpChange(event) {
    this.OTPForm.get('userOTP').setValue(event);
  }

  /*************************************************************
	  @Purpose     : SignIn
	  @Parameter   : NA
	  @Return      : NA
	/*************************************************************/
  MsgError: any;
  signInSubmit() {
    this.isSubmitting = true;
    this.isSignInsubmitted = true;
    if (this.signInForm.valid) {
      let sigInData = {
        emailId: this.signInForm.value.emailOrLoginId,
        password: this.signInForm.value.password,
      };
      // this.spinnerService.show();
      this.commonService
        .callApi(
          this.callAPIConstants.userSignInOnboarding,
          sigInData,
          'post',
          true,
          false
        )
        .then((successSigInData) => {
          this.spinnerService.hide();
          this.signInForm.patchValue({ captcha: '' });
          this.isSubmitting = false;
          if (successSigInData.status === 1) {
            this.attemptsLeft = null;
            if (successSigInData.mfaEnabled) {
              this.modalRef.hide();
              this.userEmail = successSigInData.emailId;
              this.openModal5(this.verified);
              this.OTPTimer(120);
            } else {
              if (!successSigInData.data.emailVerificationStatus) {
                // this.userEmail = successSigInData.data.emailId;
                // this.openModal5(this.verified);
                // this.OTPTimer(60);
                this.showErrorService.popToast(
                  'error',
                  'Please verify your email'
                );
              } else {
                this.showErrorService.popToast(
                  'success',
                  successSigInData.message
                );
                this.modalRef.hide();
                this.localStorageService.setToken(
                  'accessToken',
                  successSigInData.access_token
                );
                this.localStorageService.setToken(
                  'username',
                  successSigInData.data.username
                );
                this.localStorageService.setToken(
                  'currentUser',
                  JSON.stringify(successSigInData.data)
                );
                this.localStorageService.setToken(
                  'industry',
                  successSigInData.data.industry
                );
                this.localStorageService.setToken(
                  'institution',
                  successSigInData.data.institution
                );
                this.localStorageService.setToken(
                  'filterToggle',
                  JSON.stringify(false)
                );
                this.localStorageService.setToken(
                  'scrollIndexSolution',
                  JSON.stringify(0)
                );
                this.localStorageService.setToken(
                  'scrollIndex',
                  JSON.stringify(0)
                );
                this.localStorageService.setToken(
                  'dontShowOnboarding',
                  JSON.stringify(false)
                );

                let params = this.route.snapshot.queryParams;

                if (params['redirectURL']) {
                  const redirectURL = params['redirectURL'];
                  this.router
                    .navigateByUrl(redirectURL)
                    .catch(() => this.router.navigate([URLConstants.FEED]));
                } else {
                  this.router.navigate([URLConstants.FEED]);
                }
                const preferredLanguage =
                  successSigInData.data.preferredLanguage;
                this.receivedLanguage = preferredLanguage;
                this.logedUserData = JSON.parse(
                  this.localStorageService.getToken('currentUser')
                );
                this.onChange();
              }
            }
          } else {
            if (successSigInData.isProbationaryAccount) {
              this.showErrorService.popToastWithoutTimer(
                'success',
                successSigInData.message
              );
            } else {
              this.showErrorService.popToast('error', successSigInData.message);
            }
            if (successSigInData.data.isBlocked) {
              this.attemptsLeft =
                'Login blocked temporary due to wrong attempts.';
            } else {
              this.attemptsLeft =
                successSigInData.data.attemptsLeft > 1
                  ? `${successSigInData.data.attemptsLeft} attempts left`
                  : `${successSigInData.data.attemptsLeft} attempt left`;
            }
          }
        });
    }
  }
  /*************************************************************
	  @Purpose     : ResendOTP
	  @Parameter   : NA
	  @Return      : NA
	/*************************************************************/
  resendOTP() {
    this.isResendOTP = true;
    this.isTimeOut = false;
    let data = {
      emailId: this.userEmail,
    };
    this.commonService
      .callApi(this.callAPIConstants.ResendOTP, data, 'post', true, false)
      .then((reSendOTPData) => {
        if (reSendOTPData.status === 1) {
          this.isResendOTP = false;
          this.OTPTimer(120);
          this.showErrorService.popToast('success', reSendOTPData.message);
        } else {
          this.showErrorService.popToast('error', reSendOTPData.message);
        }
      });
  }

  /*************************************************************
		  @Purpose     : On Submit
		  @Parameter   : NA
		  @Return      : NA
		  /*************************************************************/
  onSubmit() {
    this.isRegsubmitted = true;
    if (this.registerForm.valid) {
      this.modalRef2.hide();
      this.userEmail = this.registerForm.value.email;
      let registerValue = {
        username: this.registerForm.value.fullName,
        emailId: this.registerForm.value.email,
        password: this.registerForm.value.password,
        termsAndCondition: this.registerForm.value.agreeTermsAndConditions,
      };
      this.commonService
        .callApi(
          this.callAPIConstants.Registration,
          registerValue,
          'post',
          true,
          false
        )
        .then((success) => {
          if (success.status === 1) {
            success.showOtp
              ? this.showErrorService.popToast('success', success.message)
              : this.showErrorService.popToastWithoutTimer(
                  'success',
                  success.message
                );
            if (success.showOtp) {
              this.openModal5(this.verified);
              this.OTPTimer(120);
            }
          } else {
            this.registerForm.reset(this.registerForm.value);
            this.modalRef.hide();
            this.MsgError = success.message;
            setTimeout(() => {
              this.openModal6(this.msg);
            }, 500);
            // this.showErrorService.popToast('error', success.message);
          }
        });
    }
  }

  onTabLinkClick(tabName: string) {
    this.tabSelectionService.selectTab(tabName);
  }

  /*************************************************************
	  @Purpose     : onOTPSubmit
	  @Parameter   : NA
	  @Return      : NA
	  /*************************************************************/
  onOTPSubmit(OTPform) {
    if (this.OTPForm.value && this.OTPForm.value.userOTP.length === 6) {
      let userOTPData = {
        emailId: this.userEmail,
        verificationCode: this.OTPForm.value.userOTP,
      };
      this.commonService
        .callApi(
          this.callAPIConstants.SubmitOTP,
          userOTPData,
          'post',
          true,
          false
        )
        .then((successSigInData) => {
          if (successSigInData.status === 1) {
            this.modalRef5.hide();
            this.showErrorService.popToast('success', successSigInData.message);
            // this.modalRef.hide();
            this.localStorageService.setToken(
              'accessToken',
              successSigInData.access_token
            );
            this.localStorageService.setToken(
              'username',
              successSigInData.data.username
            );
            this.localStorageService.setToken(
              'currentUser',
              JSON.stringify(successSigInData.data)
            );
            this.localStorageService.setToken(
              'industry',
              successSigInData.data.industry
            );
            this.localStorageService.setToken(
              'institution',
              successSigInData.data.institution
            );
            this.localStorageService.setToken(
              'filterToggle',
              JSON.stringify(false)
            );
            this.localStorageService.setToken(
              'scrollIndexSolution',
              JSON.stringify(0)
            );
            this.localStorageService.setToken('scrollIndex', JSON.stringify(0));
            this.localStorageService.setToken(
              'dontShowOnboarding',
              JSON.stringify(false)
            );

            let params = this.route.snapshot.queryParams;

            if (params['redirectURL']) {
              const redirectURL = params['redirectURL'];
              this.router
                .navigateByUrl(redirectURL)
                .catch(() => this.router.navigate([URLConstants.FEED]));
            } else {
              this.router.navigate([URLConstants.FEED]);
            }
            const preferredLanguage = successSigInData.data.preferredLanguage;
            this.receivedLanguage = preferredLanguage;
            this.onChange();
          } else {
            OTPform.setValue('');
            this.isResendOTP = true;
            clearInterval(this.countdownTimer);
            this.showErrorService.popToast('error', successSigInData.message);
          }
        });
    } else {
      this.showErrorService.popToast('error', 'Please Enter Valid OTP');
    }
  }
  /*************************************************************
	  @Purpose     : OTP counter
	  @Parameter   : NA
	  @Return      : NA
	/*************************************************************/
  OTPTimer(time) {
    this.ProgressCountdown(time, 'countTimeId').then((value) => {
      if (value) {
        this.isResendOTP = true;
        this.isTimeOut = true;
      }
    });
  }

  /*************************************************************
	  @Purpose     : closeOTPModel
	  @Parameter   : NA
	  @Return      : NA
	/*************************************************************/
  closeOTPModel() {
    this.modalRef5.hide();
    clearInterval(this.countdownTimer);
  }

  /*************************************************************
	  @Purpose     :ProgressCountdown
	  @Parameter   : timeleft,bar
	  @Return      : time
	  /*************************************************************/
  ProgressCountdown(timeleft, bar) {
    return new Promise((resolve, reject) => {
      this.countdownTimer = setInterval(() => {
        var m = Math.floor(timeleft / 60);
        var s = timeleft % 60;
        let mi = m < 10 ? '0' + m : m;
        let si = s < 10 ? '0' + s : s;
        timeleft--;

        document.getElementById(bar).innerHTML = mi + ' : ' + si;
        if (timeleft <= 0) {
          clearInterval(this.countdownTimer);
          resolve(true);
        }
      }, 1000);
    });
  }

  /*************************************************************
	  @Purpose     : RecommendDomain
	  @Parameter   : NA
	  @Return      : NA
	/*************************************************************/
  recommendDomainOnSubmit() {
    this.isRecommantedsubmitted = true;
    if (this.recommendDomain.valid) {
      let data = {
        suggestedDomain: this.recommendDomain.value.userRecommendDomain,
        domainName: this.recommendDomain.value.domainName,
      };
      this.commonService
        .callApi(this.callAPIConstants.SuggestDomain, data, 'post', true, false)
        .then((success) => {
          if (success.status === 1) {
            this.modalRef4.hide();
          } else {
            this.showErrorService.popToast('error', success.message);
          }
        });
    }
  }
  /*************************************************************
	  @Purpose     : RecommendDomain
	  @Parameter   : NA
	  @Return      : NA
	/*************************************************************/
  domainlists: any = [];
  count: any = 1;
  domainlist(count) {
    this.count += 1;
    if (count == 1) {
      this.commonService
        .callApi(this.callAPIConstants.DomainList, '', 'get', true, false)
        .then((success) => {
          this.domainlists = success.domainList;
        });
    }
  }
  /*************************************************************
	/*************************************************************
		  @Purpose     : logout
		  @Parameter   : NA
		  @Return      : NA
  /*************************************************************/
  logout() {
    this.logoutService.logout();
  }

  /*************************************************************
	  @Purpose     : Apply filter
	  @Parameter   : NA
	  @Return      : NA
	/*************************************************************/
  filterSubmit(reset) {
    this.isFilterSubmitted = true;
    if (this.filterForm.valid) {
      let filterArray = [];

      filterArray.push({
        hospital: this.filterForm.value.hospitalName,
      });
      filterArray.push({
        country: this.filterForm.value.country
          ? this.filterForm.value.country
          : '',
      });
      filterArray.push({
        department: this.filterForm.value.department
          ? this.filterForm.value.department
          : '',
      });
      filterArray.push({
        medicationError: this.filterForm.value.medicationeError
          ? this.filterForm.value.medicationeError
          : '',
      });
      filterArray.push({
        region: this.filterForm.value.region
          ? this.filterForm.value.region
          : '',
      });
      filterArray.push({
        subRegion: this.filterForm.value.subRegion
          ? this.filterForm.value.subRegion
          : '',
      });

      filterArray.push({
        procedures:
          this.filterForm.value.procedures?.length !== 0
            ? this.filterForm.value.procedures
            : '',
      });

      this.GlobalFilterService.sendGlobalFilter(filterArray, reset);

      this.postFilterData(filterArray);
      this.modalRef7.hide();
      this.isFilterSubmitted = false;
    }
  }

  /*************************************************************
	  @Purpose     : Apply filter aviation
	  @Parameter   : NA
	  @Return      : NA
	/*************************************************************/
  filterSubmitAviation(reset) {
    this.isFilterSubmitted = true;
    if (this.filterFormAviation.valid) {
      let filterArray = [];

      filterArray.push({
        airportOrAirstrip: this.filterFormAviation.value.airstrip
          ? this.filterFormAviation.value.airstrip
          : '',
      });
      filterArray.push({
        otherAirportOrAirstrip: this.filterFormAviation.value.otherAirstrip
          ? this.filterFormAviation.value.otherAirstrip
          : '',
      });
      filterArray.push({
        aviationCountry: this.filterFormAviation.value.country
          ? this.filterFormAviation.value.country
          : '',
      });
      filterArray.push({
        type: this.filterFormAviation.value.type
          ? this.filterFormAviation.value.type
          : '',
      });
      filterArray.push({
        expertise: this.filterFormAviation.value.expertise
          ? this.filterFormAviation.value.expertise
          : '',
      });
      // filterArray.push({
      //   placeOfService: this.filterFormAviation.value.placeOfService
      //     ? this.filterFormAviation.value.placeOfService
      //     : '',
      // });
      filterArray.push({
        region: this.filterFormAviation.value.region
          ? this.filterFormAviation.value.region
          : '',
      });
      filterArray.push({
        airline: this.filterFormAviation.value.airline
          ? this.filterFormAviation.value.airline
          : '',
      });
      filterArray.push({
        procedures:
          this.filterFormAviation.value.procedures?.length !== 0
            ? this.filterFormAviation.value.procedures
            : '',
      });

      this.GlobalFilterService.sendGlobalFilter(filterArray, reset);

      this.postFilterData(filterArray);
      this.modalRef7.hide();
      this.isFilterSubmitted = false;
    }
  }

  /*************************************************************
	@Purpose     : Search Header
	@Parameter   : NA
	@Return      : NA
	/*************************************************************/
  universalSearch(event, key) {
    if (
      key === 'enter' &&
      (this.mysearch['searchTerm']?.length || event.name)
    ) {
      if (this.localStorageService.getToken('globalSearchValue')) {
        this.searchArr = JSON.parse(
          this.localStorageService.getToken('globalSearchValue')
        );
      }
      this.searchArr.push({
        name: this.mysearch['searchTerm']
          ? this.mysearch['searchTerm']
          : event.name,
      });
      this.searchArr = this.searchArr.reduce((unique, o) => {
        if (!unique.some((obj) => obj.name === o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);

      if (this.searchArr.length < 16) {
        this.localStorageService.setToken(
          'globalSearchValue',
          JSON.stringify(this.searchArr)
        );
      }
      this.GlobalSearch.sendGlobalSearch(
        this.mysearch['searchTerm'] ? this.mysearch['searchTerm'] : event.name
      );
      this.searchText = this.mysearch['searchTerm']
        ? this.mysearch['searchTerm']
        : event.name;
      this.commonService.router.navigate([this.URLConstants.FEED]);
    }
  }
  /*************************************************************
	@Purpose     : clearSearch
	@Parameter   : NA
	@Return      : NA
	/*************************************************************/
  clearSearch() {
    let data = '';
    this.GlobalSearch.sendGlobalSearch(data);
    if (JSON.parse(this.localStorageService.getToken('globalSearchValue'))) {
      this.searchItems = JSON.parse(
        this.localStorageService.getToken('globalSearchValue')
      );
    } else {
      this.searchItems = [];
    }
  }
  /*************************************************************
		@Purpose     : onFocus
		@Parameter   : NA
		@Return      : NA
		/*************************************************************/
  onFocus() {
    if (JSON.parse(this.localStorageService.getToken('globalSearchValue'))) {
      const searchArr = JSON.parse(
        this.localStorageService.getToken('globalSearchValue')
      );

      this.searchItems = searchArr.map(
        (item) => (item['type'] = 'Recent Searches')
      );
    } else {
      this.searchItems = [];
    }
  }

  /****************************************************************************
   @Purpose     : GetIncidentDefaultData
   @Parameter   : Na
   @Return      : NA
   /****************************************************************************/
  getIncidentData() {
    // this.setIncidentForm();
    this.commonService
      .callApi(this.callAPIConstants.getIncidentData, '', 'get', false, false)
      .then((success) => {
        if (success.status === 1) {
          this.deparmentList = success.data.departmentList;
          this.regionList = success.data.regionList;
          this.aviationRegionList = success.data.aviationRegionList;
          this.temporaryAviationRegionsStore = success.data.aviationRegionList;
          this.subRegionList = success.data.subRegionList;
          this.temporaryRegionsStore = success.data.regionList;
          this.temporarySubRegionsStore = success.data.subRegionList;
          // this.temporaryPlaceOfServiceStore = success.data.placeOfServiceList;
          this.temporaryAirlineStore = success.data.airlineList;
          // this.tagListArray = success.data.tagList;
          this.germanTagListArrayTemp = success.data.tagListGerman;
          this.frenchTagListArrayTemp = success.data.tagListFrench;
          this.englishTagListArrayTemp = success.data.tagList;

          if (this.receivedLanguage === 'en') {
            this.tagListArray = this.englishTagListArrayTemp;
            this.medicationErrors = success.data.tagList.filter((tag) =>
              tag.name.startsWith('medication error')
            );
          } else if (this.receivedLanguage === 'de') {
            this.tagListArray = this.germanTagListArrayTemp;
            this.medicationErrors = success.data.tagListGerman.filter((tag) =>
              tag.name.startsWith('Medikamentenfehler')
            );
          } else if (this.receivedLanguage === 'fr') {
            this.tagListArray = this.frenchTagListArrayTemp;
            this.medicationErrors = success.data.tagListFrench.filter((tag) =>
              tag.name.startsWith('erreur de médicament')
            );
          }

          let countryArr = [];
          success.data.countryList.forEach((country) => {
            countryArr.push({ name: country.name });
          });
          this.countries = countryArr;
          this.airlineList = success.data.airlineList;
          // this.placeOfServiceList = success.data.placeOfServiceList;
          this.expertiseList = success.data.expertiseList;
          this.getFilterData();
        } else {
          this.showErrorService.popToast('error', success.message);
          this.modalRef7.hide();
          this.isFilterSubmitted = false;
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  /****************************************************************************
   @Purpose     : GetUserFilter
   @Parameter   : Na
   @Return      : NA
   /****************************************************************************/
  getFilterData() {
    this.commonService
      .callApi(this.callAPIConstants.userGetFilter, '', 'get', false, false)
      .then((success) => {
        if (success.status === 1) {
          // success.data.filters.forEach((filterItem) => {
          //   if (filterItem.industry === '') {
          //     this.industry = this.userData.industry;
          //   }
          //   if (filterItem.industry && filterItem.industry !== '') {
          //     this.industry = filterItem.industry;
          //     this.filterForm.patchValue({
          //       industry: filterItem.industry,
          //     });
          //     this.filterFormAviation.patchValue({
          //       industry: filterItem.industry,
          //     });
          //   }
          // });
          if (this.userData.industry === 'healthcare') {
            success.data.filters.forEach((filterItem) => {
              if (filterItem.hospital && filterItem.hospital !== '') {
                this.filterForm.patchValue({
                  hospitalName: filterItem.hospital,
                });
              }
              if (filterItem.country && filterItem.country !== '') {
                this.filterForm.patchValue({
                  country: filterItem.country,
                });
                let regions = this.regionList.filter(
                  (region) => region.country === filterItem.country
                );
                this.regionList = regions;
              }
              if (filterItem.department && filterItem.department !== '') {
                this.filterForm.patchValue({
                  department: filterItem.department,
                });
              }
              if (
                filterItem.medicationError &&
                filterItem.medicationError !== ''
              ) {
                this.filterForm.patchValue({
                  medicationeError: filterItem.medicationError,
                });
              }
              if (filterItem.region && filterItem.region !== '') {
                this.filterForm.patchValue({
                  region: filterItem.region,
                });

                let subRegions = [];
                this.regionList.forEach((region) => {
                  if (region.name === filterItem.region) {
                    subRegions = region.subRegionList;
                  }
                });
                this.subRegionList = subRegions;
              }
              if (filterItem.subRegion && filterItem.subRegion !== '') {
                this.filterForm.patchValue({
                  subRegion: filterItem.subRegion,
                });
              }
              if (filterItem.procedures && filterItem.procedures !== '') {
                this.filterForm.patchValue({
                  procedures: filterItem.procedures,
                });
              }
            });
          }
          if (this.userData.industry === 'aviation') {
            success.data.filters.forEach((filterItem) => {
              if (filterItem.type && filterItem.type !== '') {
                this.typeSelected = false;
                this.filterFormAviation.patchValue({
                  type: filterItem.type,
                });

                if (
                  filterItem.type === 'Private' ||
                  filterItem.type === 'Commercial'
                ) {
                  this.privateOrCommercialSelected = true;
                  this.airlineSelected = false;
                }
                if (filterItem.type === 'Airline') {
                  this.privateOrCommercialSelected = false;
                  this.airlineSelected = true;
                }
                if (filterItem.type === 'Military') {
                  this.privateOrCommercialSelected = false;
                  this.airlineSelected = false;
                }
              }
              if (
                filterItem.airportOrAirstrip &&
                filterItem.airportOrAirstrip !== ''
              ) {
                this.filterFormAviation.patchValue({
                  airstrip: filterItem.airportOrAirstrip,
                });
              }
              if (
                filterItem.otherAirportOrAirstrip &&
                filterItem.otherAirportOrAirstrip !== ''
              ) {
                this.filterFormAviation.patchValue({
                  otherAirstrip: filterItem.otherAirportOrAirstrip,
                });
              }
              if (
                filterItem.aviationCountry &&
                filterItem.aviationCountry !== ''
              ) {
                this.filterFormAviation.patchValue({
                  country: filterItem.aviationCountry,
                });
                let regions = this.aviationRegionList.filter(
                  (region) => region.country === filterItem.aviationCountry
                );
                this.aviationRegionList = regions;

                // let placeOfServices = this.placeOfServiceList.filter(
                //   (item) => item.country === filterItem.aviationCountry
                // );
                // this.placeOfServiceList = placeOfServices;

                let airlines = this.airlineList.filter(
                  (item) => item.country === filterItem.aviationCountry
                );
                this.airlineList = airlines;
              }
              if (filterItem.expertise && filterItem.expertise !== '') {
                this.filterFormAviation.patchValue({
                  expertise: filterItem.expertise,
                });
              }
              // if (
              //   filterItem.placeOfService &&
              //   filterItem.placeOfService !== ''
              // ) {
              //   this.filterFormAviation.patchValue({
              //     placeOfService: filterItem.placeOfService,
              //   });
              // }
              if (filterItem.region && filterItem.region !== '') {
                this.filterFormAviation.patchValue({
                  region: filterItem.region,
                });
              }
              if (filterItem.airline && filterItem.airline !== '') {
                this.filterFormAviation.patchValue({
                  airline: filterItem.airline,
                });
              }
              if (filterItem.procedures && filterItem.procedures !== '') {
                this.filterForm.patchValue({
                  procedures: filterItem.procedures,
                });
              }
            });
          }

          if (this.industry == null) {
            this.industry = this.userData.industry;
          }
        } else {
          this.showErrorService.popToast('error', success.message);
          if (success.message === 'Password needs to be changed.') {
            setTimeout(() => {
              this.router.navigate([URLConstants.SETTINGS]);
            }, 2500);
          }
        }
      });
  }

  /****************************************************************************
   @Purpose     : postUserFilter
   @Parameter   : filterArray: Array
   @Return      : NA
   /****************************************************************************/
  postFilterData(filterArray) {
    this.commonService
      .callApi(
        this.callAPIConstants.userEditFilter,
        { filters: filterArray },
        'post',
        false,
        false
      )
      .then((success) => {
        if (success.status === 1) {
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
  }

  /****************************************************************************
   @Purpose     : resetUserFilter
   @Parameter   : NA
   @Return      : NA
   /****************************************************************************/
  resetFilterData() {
    this.industry = null;
    if (this.userData.industry === 'healthcare') {
      this.filterForm = this.formBuilder.group({
        hospitalName: [''],
        country: [null],
        region: [null],
        subRegion: [null],
        department: [null],
        procedures: [[]],
        medicationeError: [null],
      });

      this.filterSubmit(true);
    }

    if (this.userData.industry === 'aviation') {
      this.typeSelected = true;
      this.otherAirportSelected = false;
      this.filterFormAviation = this.formBuilder.group({
        airstrip: [null],
        country: [null],
        region: [null],
        type: [null],
        expertise: [null],
        procedures: [[]],
        airline: [null],
        otherAirstrip: [null],
        // placeOfService: [null],
      });
      this.filterSubmitAviation(true);
    }
  }

  /****************************************************************************
   @Purpose     : scroll to top of feed
   @Parameter   : NA
   @Return      : NA
   /****************************************************************************/
  scrollBackToTop() {
    this.scrollService.sendScrollEvent();
  }
  closeNavbar() {
    if (window.innerWidth < 992) {
      const navbarToggler = document.querySelector('.navbar-toggler');
      const navbarCollapse = document.querySelector('.navbar-collapse.show');

      if (navbarToggler && navbarCollapse) {
        this.renderer.removeClass(navbarToggler, 'collapsed');
        this.renderer.removeClass(navbarCollapse, 'show');
        this.renderer.setAttribute(navbarToggler, 'aria-expanded', 'false');
        this.renderer.removeAttribute(navbarCollapse, 'style');
      }
    }
  }

  reloadComponent(): void {
    // const currentUrl = this.activatedRoute.snapshot.url.join('/');
    const redirectUrl = this.isAuthUser ? 'feed' : 'home';
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([redirectUrl]);
  }

  private loadTypehead() {
    this.airportListArray = concat(
      of([]),
      this.typeAheadSource.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term) => this.searchAirport(term)),
        map((response) => {
          return response;
        })
      )
    );
  }

  private loadTypeheadSearch() {
    this.searchItems = concat(
      of([]),
      this.typeAheadSourceSearch.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap((term) => this.searchSuggestion(term)),
        map((response) => {
          return response;
        })
      )
    );
  }

  searchAirport(term: string = null): Observable<any> {
    if (!term) {
      return of([]);
    }
    let dataParams: any;
    dataParams = {
      page: 1,
      pagesize: 10,
      searchTerm: term,
    };

    let items = [];
    return this.commonService
      .callApiObservable(this.callAPIConstants.airportSearch, dataParams)
      .pipe(
        catchError(() => of({ items: [] })),
        map((success) => {
          if (success['status'] == 1) {
            items = success['data'];
            items.unshift({ name: 'Other Airport' });
          }
          return items ? items : [];
        })
      );
  }

  searchSuggestion(term: string = null): Observable<any> {
    if (!term) {
      return of([]);
    }
    let dataParams: any;
    dataParams = {
      page: 1,
      pagesize: 10,
      searchTerm: term,
    };

    let items = [];
    return this.commonService
      .callApiObservable(this.callAPIConstants.searchSuggestion, dataParams)
      .pipe(
        catchError(() => of({ items: [] })),
        map((success) => {
          if (success['status'] == 1) {
            items = success['data'];
          }
          return items ? items : [];
        })
      );
  }

  setValueForAirport(value) {
    if (value) {
      if (value.name == 'Other Airport') {
        this.otherAirportSelected = true;
      } else {
        this.filterFormAviation.get('otherAirstrip').setValue(null);
        this.otherAirportSelected = false;
      }
    } else {
      this.filterFormAviation.get('otherAirstrip').setValue(null);
      this.otherAirportSelected = false;
    }
  }
}
